import {
  FETCH_ORDER_HISTORY_REQUEST,
  FETCH_ORDER_HISTORY_SUCCESS,
  FETCH_ORDER_HISTORY_FAILURE,
} from './actionTypes';

import { OrdersActions, OrdersState } from './types';

const initialState: OrdersState = {
  pending: false,
  data: [],
  error: null,
};

export const ordersReducer = (state = initialState, action: OrdersActions) => {
  switch (action.type) {
    case FETCH_ORDER_HISTORY_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_ORDER_HISTORY_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.payload.orders.data,
        error: null,
      };
    case FETCH_ORDER_HISTORY_FAILURE:
      return {
        ...state,
        pending: false,
        data: [],
        error: action.payload.error,
      };

    case 'persist/REHYDRATE':
      return {
        ...state,
        data:
          action.payload && action.payload.orders && action.payload.orders.data
            ? action.payload.orders.data
            : [],
      };
    default:
      return {
        ...state,
      };
  }
};
