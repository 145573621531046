import { ReactNode } from 'react';
import { TabDetails } from './tab/TabDetails';
import { TabSummary } from './tab/TabSummary';
import { toAbsoluteUrl } from '@helpers/index';
import { Divider } from '@layout/components/Divider';
import { Button } from '@components/button/Button';

export interface ITab {
  key: string | number;
  summary: ReactNode;
  details: any;
}

export type TabsProps = {
  tabs: ITab[];
  activeTab: ITab | null;
  handleChange: (tab: ITab) => void;
  handleAction?: () => void;
};

export const Tabs = ({ tabs, activeTab, handleChange, handleAction }: TabsProps) => {
  return (
    <div className="my-6 md:flex tabs">
      <nav className="md:shadow-xl rounded-tl-[3rem] rounded-tr-[3rem] md:rounded-tr-none md:rounded-bl-[3rem] py-[3rem] bg-light-100 md:w-1/3">
        {tabs.map((tab: ITab) => (
          <TabSummary
            id={tab.key}
            key={tab.key}
            handleActive={() => handleChange(tab)}
            active={tab.key === activeTab?.key}
          >
            {tab.summary}
          </TabSummary>
        ))}

        {handleAction && (
          <>
            <div className="pt-[3rem] mx-[2.5rem]">
              <Divider />
            </div>

            <div className="flex mx-auto py-[2.5rem] justify-center">
              <Button
                variant="simple"
                icon={toAbsoluteUrl('/media/svg/general/plus.svg')}
                iconPosition="before"
                color="primary"
                onClick={handleAction}
              >
                Add New
              </Button>
            </div>
          </>
        )}
      </nav>
      {tabs.map((tab) =>
        activeTab?.key === tab.key ? (
          <div key={tab.key} className="md:w-2/3">
            <TabDetails active={true}>{activeTab?.details}</TabDetails>
          </div>
        ) : null,
      )}
    </div>
  );
};
