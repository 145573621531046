import { createSelector } from 'reselect';

import { RootState } from '../../../../store/store';

const getPending = (state: RootState) => state.paymentMethods.pending;

const getPaymentMethods = (state: RootState) => state.paymentMethods.data;

const getError = (state: RootState) => state.paymentMethods.error;

export const getPaymentMethodsSelector = createSelector(
  getPaymentMethods,
  (paymentMethods) => paymentMethods,
);

export const getBillingAddressesSelector = createSelector(getPaymentMethods, (paymentMethods) =>
  paymentMethods.map((paymentMethod: any) => ({
    id: paymentMethod.id,
    ...paymentMethod.billingAddress,
  })),
);

export const getPaymentDetailsSelector = createSelector(getPaymentMethods, (paymentMethods) =>
  paymentMethods.map((paymentMethod: IPayment) => paymentMethod),
);

export const getPaymentDetailsSelectorById = (id: number) =>
  createSelector(getPaymentMethods, (paymentMethods) =>
    paymentMethods.find((paymentMethod: any) => paymentMethod.id === id),
  );

export const getPendingSelector = createSelector(getPending, (pending) => pending);

export const getErrorSelector = createSelector(getError, (error) => error);
