import { createSelector } from 'reselect';

import { RootState } from '../../../../store/store';
import { getDefaultSubscriptionSelector } from '@modules/subscriptions/core/selectors';
import { mapOrderSchedule } from '@modules/orders/utils/mapOrdersSchedule';

const getPending = (state: RootState) => state.orderSchedule.pending;

const getOrderSchedule = (state: RootState) => state.orderSchedule.data;

const getError = (state: RootState) => state.orderSchedule.error;

export const getOrderScheduleSelector = createSelector(
  [getOrderSchedule, getDefaultSubscriptionSelector],
  (orderSchedule, defaultSubscription) => {
    const unprocessedOrderSchedule = orderSchedule.filter(
      (order: IOrder) => order?.status !== 'SKIPPED',
    );
    return mapOrderSchedule(unprocessedOrderSchedule.slice(1), defaultSubscription);
  },
);

export const getCurrentOrderSelector = createSelector(
  [getOrderSchedule, getDefaultSubscriptionSelector],
  (orderSchedule, defaultSubscription) => {
    if (orderSchedule.length === 0) {
      return null;
    }

    const unprocessedOrderSchedule = orderSchedule.filter(
      (order: IOrder) => order?.status !== 'SKIPPED',
    );

    return mapOrderSchedule([unprocessedOrderSchedule[0]], defaultSubscription)?.[0];
  },
);

export const getPendingSelector = createSelector(getPending, (pending) => pending);

export const getErrorSelector = createSelector(getError, (error) => error);
