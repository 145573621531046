import { createSelector } from 'reselect';
import { RootState } from '../../../../store/store';
import { IShippingAddress } from '@modules/order-schedule/core/types';

const getPending = (state: RootState) => state.addresses.pending;

const getAddresses = (state: RootState) => state.addresses.data;

const getError = (state: RootState) => state.addresses.error;

export const getAddressesSelector = createSelector(getAddresses, (addresses) => addresses);

export const getPendingSelector = createSelector(getPending, (pending) => pending);

export const getErrorSelector = createSelector(getError, (error) => error);

export const getUniqueAddressesSelector = createSelector(getAddressesSelector, (addresses) =>
  addresses.filter(
    (address: IShippingAddress, index: number, self: IShippingAddress[]) =>
      index ===
      self.findIndex(
        (t) =>
          t.address1 === address.address1 &&
          t.zip === address.zip &&
          t.city === address.city &&
          t.first_name === address.first_name &&
          t.last_name === address.last_name,
      ),
  ),
);
