import axios, { AxiosResponse } from 'axios';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import {
  fetchOrderScheduleFailure,
  fetchOrderScheduleRequest,
  fetchOrderScheduleSuccess,
  skipOrderRequest,
  skipOrderSuccess,
  skipOrderFailure,
  unskipOrderRequest,
  unskipOrderSuccess,
  unskipOrderFailure,
} from './actions';
import {
  FETCH_ORDER_SCHEDULE_REQUEST,
  SKIP_ORDER_REQUEST,
  UNSKIP_ORDER_REQUEST,
} from './actionTypes';
import { FetchOrderScheduleRequestPayload } from './types';

const getOrderSchedule = async (subscriptionId: number) => {
  const params: FetchOrderScheduleRequestPayload = {
    subscriptionId,
  };

  const response = await axios.get<any>(`/api/v1/subscriptions/${subscriptionId}/order_schedule`, {
    params,
  });

  return response.data;
};

const skipOrder = async (id: number) => {
  const response = await axios.post<any>(`/api/v1/orders/${id}/skip`);
  return response.data;
};
const unskipOrder = async (id: number) => {
  const response = await axios.post<any>(`/api/v1/orders/${id}/unskip`);
  return response.data;
};

function* fetchOrderScheduleSaga(action: ReturnType<typeof fetchOrderScheduleRequest>) {
  try {
    const response: AxiosResponse<any> = yield call(() =>
      getOrderSchedule(action.payload.subscriptionId),
    );

    yield put(
      fetchOrderScheduleSuccess({
        orderSchedule: response.data,
      }),
    );
  } catch (e: any) {
    yield put(
      fetchOrderScheduleFailure({
        error: e.message,
      }),
    );
  }
}

function* skipOrderSaga(action: ReturnType<typeof skipOrderRequest>) {
  try {
    const response: AxiosResponse<IOrder> = yield call(() => skipOrder(action.payload.id));

    yield put(
      skipOrderSuccess({
        order: response.data,
      }),
    );
  } catch (e: any) {
    yield put(
      skipOrderFailure({
        error: e.message,
      }),
    );
  }
}

function* unskipOrderSaga(action: ReturnType<typeof unskipOrderRequest>) {
  try {
    const response: AxiosResponse<IOrder> = yield call(() => unskipOrder(action.payload.id));

    yield put(
      unskipOrderSuccess({
        order: response.data,
      }),
    );
  } catch (e: any) {
    yield put(
      unskipOrderFailure({
        error: e.message,
      }),
    );
  }
}

export function* orderScheduleSaga() {
  yield all([
    takeLatest(FETCH_ORDER_SCHEDULE_REQUEST, fetchOrderScheduleSaga),
    takeLatest(SKIP_ORDER_REQUEST, skipOrderSaga),
    takeLatest(UNSKIP_ORDER_REQUEST, unskipOrderSaga),
  ]);
}
