import { FC } from 'react';
import { Routes, Route, Navigate, BrowserRouter } from 'react-router-dom';
import { PrivateRoutes } from './PrivateRoutes';
import { ErrorsPage } from '../modules/errors/ErrorsPage';
import { Logout, AuthPage } from '../modules/auth';
import { App } from '../App';
import { useSelector } from 'react-redux';
import { getAuthTokenSelector, getIsLoggedIn } from '../modules/auth/core/selectors';
import { useQuery } from 'src/shared';

const { PUBLIC_URL } = process.env;

const AppRoutes: FC = () => {
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <MainRoutes />
    </BrowserRouter>
  );
};

const MainRoutes: FC = () => {
  const currentUser: boolean = useSelector(getIsLoggedIn);
  const accessToken = useSelector(getAuthTokenSelector);
  const { getQueryParam } = useQuery();

  const sessionToken = getQueryParam('sessionToken');
  const localSessionToken = localStorage.getItem('auth.sessionToken');

  const shouldRelogin = Boolean(
    sessionToken && localSessionToken && sessionToken !== localSessionToken && accessToken,
  );

  return (
    <Routes>
      <Route element={<App />}>
        <Route path="error/*" element={<ErrorsPage />} />
        <Route path="logout" element={<Logout />} />
        {currentUser && !shouldRelogin ? (
          <>
            <Route path="/*" element={<PrivateRoutes />} />
            <Route index element={<Navigate to="/dashboard" />} />
          </>
        ) : (
          <>
            <Route path="auth/*" element={<AuthPage shouldRelogin={shouldRelogin} />} />
            <Route path="*" element={<Navigate to="/auth" />} />
          </>
        )}
      </Route>
    </Routes>
  );
};

export { AppRoutes };
