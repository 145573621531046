import { toAbsoluteUrl } from '@helpers/AssetHelpers';
import { KTSVG } from '@helpers/index';

export const RafLink = () => {
  return (
    <div className="raf">
      <picture className="mr-[5px]">
        <source
          media="(max-width: 768px)"
          srcSet={toAbsoluteUrl(`/media/svg/aside/raf-gift-mobile.svg`)}
        />
        <source
          media="(min-width: 768px)"
          srcSet={toAbsoluteUrl(`/media/svg/aside/raf-gift-desktop.svg`)}
        />
        <img src={toAbsoluteUrl(`/media/svg/aside/raf-gift-mobile.svg`)} alt="Raf Gift" />
      </picture>

      <p className="raf-text">Erhalte mehr Belohnungen!</p>
      <div className="flex gap-[5px] items-center">
        <a href="/raf" className="flex items-center">
          <span className="raf-link md:mr-[5px]">Jetzt Freu(n)de empfehlen</span>
          <KTSVG className="raf-arrow" path={toAbsoluteUrl('/media/svg/general/raf-arrow.svg')} />
        </a>
      </div>
    </div>
  );
};
