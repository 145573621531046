import { SubscriptionReactivationModalInfo } from './SubscriptionReactivationModalInfo';
import { SubscriptionReactivationModalPayment } from './SubscriptionReactivationModalPayment';

export const SubscriptionReactivationModal = () => {
  return (
    <div className="subscription-reactivation-modal">
      <div>
        <h4>VERSANDADRESSE</h4>

        <SubscriptionReactivationModalInfo />
      </div>

      <div>
        <h4 className="justify-center md:flex">Zahlungsarten</h4>
        <SubscriptionReactivationModalPayment />
      </div>
    </div>
  );
};
