import { useSelector, useDispatch } from 'react-redux';
import { getUniqueAddressesSelector } from '@modules/addresses/core/selectors';
import { getCustomerSelector } from '@modules/customer';
import { getDefaultSubscriptionSelector } from '@modules/subscriptions/core/selectors';
import { useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useClickOutside } from 'src/shared';
import { Link } from 'react-router-dom';
import { Dropdown } from '@layout/components/aside/dropdown/Dropdown';
import { DropdownItem } from '@layout/components/aside/dropdown/DropdownItem';
import { KTSVG, toAbsoluteUrl } from '@helpers/index';
import { updateSubscriptionAddressRequest } from '@modules/subscriptions/core/actions';
import { COUNTRIES } from '@constants/common';

export const SubscriptionReactivationModalInfo = () => {
  const customer = useSelector(getCustomerSelector);
  const addresses = useSelector(getUniqueAddressesSelector);
  const defaultSubscription = useSelector(getDefaultSubscriptionSelector);
  const dispatch = useDispatch();
  const intl = useIntl();
  const ref = useRef<HTMLDivElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(addresses?.[0]);

  useClickOutside(ref, () => setIsOpen(false));

  const handleOpen = (event: React.MouseEvent) => {
    event.stopPropagation();
    setIsOpen((prev) => !prev);
  };

  const { address1, city, countryCode, zip } = selectedAddress || {};
  const country = COUNTRIES.find((country: any) => country.code === countryCode);
  const countryName = country?.translation
    ? intl.formatMessage({ id: country?.translation })
    : countryCode;

  const handleAddressChange = (newAddress: any) => {
    setSelectedAddress(newAddress);
    dispatch(
      updateSubscriptionAddressRequest({
        id: defaultSubscription?.id,
        params: {
          firstName: newAddress.firstName ?? '',
          lastName: newAddress.lastName,
          phone: newAddress.phone ?? '',
          company: newAddress.company ?? '',
          address1: newAddress.address1,
          city: newAddress.city,
          countryCode: newAddress.countryCode,
          zip: newAddress.zip ?? '',
        },
      }),
    );
    setIsOpen(false);
  };

  return (
    <div className="subscription-reactivation-modal-customer">
      <ul>
        <li className="md:mb-[2.4rem]">
          <p> Name</p>

          <span className="mb-[2rem] md:mb-[2.4rem]">
            {customer?.firstName} {customer?.lastName}
          </span>
        </li>

        <li>
          <p> Versandadresse</p>

          <span>
            {address1 ? (
              <>
                {address1}, <br />
                {zip} {city}, {countryName}
              </>
            ) : (
              'Keine Adresse ausgewählt'
            )}
          </span>
        </li>
      </ul>
      <div className="" ref={ref}>
        <button className="subscription-reactivation-modal-address-dropdown" onClick={handleOpen}>
          <span>Versandadresse ändern</span>
          <span className={`${!isOpen ? '' : 'rotate-180'} transition-transform`}>
            <KTSVG path={toAbsoluteUrl('/media/svg/general/down-arrow.svg')} />
          </span>
        </button>
        {isOpen && (
          <div className="z-50 menu-panel menu-panel-address">
            <Dropdown>
              {addresses.map((address: any) => (
                <DropdownItem
                  key={address?.id}
                  onClick={() => handleAddressChange(address)}
                  title={`${address?.address1}, ${address?.zip} ${address?.city}, ${
                    countryName || address?.countryCode
                  } `}
                  isActive={selectedAddress?.id === address?.id}
                />
              ))}
            </Dropdown>
            <Link to="/profile">
              <div className="add-shipping-address">+ Versandadresse ändern</div>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};
