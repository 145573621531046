import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import { SwiperOptions } from 'swiper/types/swiper-options';
import 'swiper/swiper-bundle.css';

export const ALL_SUPPLEMENTS = [
  {
    id: '1',
    title: 'GELENK DROPS',
    text: 'Der gesunde Hundekeks für Gelenke & Knochen',
    img: '/media/img/supplements/gelenkdrops.png',
    backgroundColor: 'rgba(147, 200, 216, 0.2)',
    inactiveBgColor: 'rgba(147, 200, 216)',
    boxShadow: 'rgba(147, 200, 216, 1) 0px 0px 5px',
    link: 'https://www.hellobello.de/products/gelenk-drops-2024',
    ingredientPopupImg: '/media/img/supplements/gelenkdrops-ingredients.png',
  },
  {
    id: '2',
    title: 'HAUT & FELL DROPS',
    text: 'Denn wahre Schönheit kommt von innen',
    img: '/media/img/supplements/haut-fell.png',
    backgroundColor: 'rgba(242, 168, 161, 0.2)',
    inactiveBgColor: 'rgba(242, 168, 161)',
    boxShadow: 'rgba(242, 168, 161, 1) 0px 0px 5px',
    link: 'https://www.hellobello.de/products/haut-fell-drops-2024',
    ingredientPopupImg: '/media/img/supplements/haut-fell-ingredients.png',
  },
  {
    id: '3',
    title: 'MAGEN-DARM DROPS',
    text: 'Weil Gesundheit durch den Magen geht',
    img: '/media/img/supplements/magen-darm.png',
    backgroundColor: 'rgba(221, 224, 242, 0.2)',
    inactiveBgColor: 'rgba(221, 224, 242)',
    boxShadow: 'rgba(221, 224, 242, 1) 0px 0px 5px',
    link: 'https://www.hellobello.de/products/magen-darm-drops-2024',
    ingredientPopupImg: '/media/img/supplements/magen-darm-ingredients.png',
  },
  {
    id: '4',
    title: 'ZAHN SHINE POPS',
    text: 'Schenke deinem Hund sein schönstes Lächeln',
    img: '/media/img/supplements/zahn-shine.png',
    backgroundColor: 'rgba(119, 145, 170, 0.2)',
    inactiveBgColor: 'rgba(119, 145, 170)',
    boxShadow: 'rgba(119, 145, 170, 1) 0px 0px 5px',
    link: 'https://www.hellobello.de/products/zahn-shine-pops-2024',
    ingredientPopupImg: '/media/img/supplements/zahn-shine-ingredients.png',
  },
];

export const SUPPLEMENT_SLIDER: SwiperOptions = {
  modules: [Navigation, Pagination, Autoplay],
  pagination: { clickable: true, el: '.supplement-slider-dots' },
  navigation: {
    nextEl: '.custom-swiper-button-prev',
    prevEl: '.custom-swiper-button-next',
  },
  autoplay: true,
  speed: 700,
  slidesPerView: 1.2,

  loop: true,

  breakpoints: {
    320: {
      slidesPerView: 1.2,
      spaceBetween: 15,
      centeredSlides: false,
    },
    768: {
      slidesPerView: 1,
      spaceBetween: 15,
    },
    1024: {
      centeredSlides: false,
      slidesPerView: 1,
      spaceBetween: 15,
    },
    1280: {
      slidesPerView: 1.3,
      spaceBetween: 15,
      centeredSlides: true,
    },
  },
};

export const PET_PROFILE_SLIDER: SwiperOptions = {
  speed: 600,
  slidesPerView: 'auto',
  spaceBetween: 10,
  breakpoints: {
    320: {
      slidesPerView: 'auto',
      spaceBetween: 10,
    },
    1024: {
      slidesPerView: 'auto',
      spaceBetween: 15,
    },
  },
};
