import clsx from 'clsx';
import { ReactNode } from 'react';

export type HeadlineProps = {
  type?: 'primary' | 'secondary' | 'neutral';
  children?: ReactNode;
};

export const Headline = ({ type = 'primary', children }: HeadlineProps) => {
  return (
    <div
      className={clsx(
        'headline-wrapper',
        { 'text-primary': type === 'primary' },
        { 'text-secondary': type === 'secondary' },
        { 'text-[#4d4d4d] md:mb-[3rem]': type === 'neutral' },
      )}
    >
      <h2 className="headline">{children}</h2>
      <div
        className={clsx(
          'headline-line',
          { 'headline-primary': type === 'primary' },
          { 'headline-secondary': type === 'secondary' },
          { 'headline-neutral': type === 'neutral' },
        )}
      ></div>
    </div>
  );
};
