export enum AuthAction {
  REQUEST_LOGIN_LINK = 'AUTH/REQUEST_LOGIN_LINK',
  REQUEST_LOGIN_LINK_SUCCESS = 'AUTH/REQUEST_LOGIN_LINK_SUCCESS',
  REQUEST_LOGIN_LINK_FAILURE = 'AUTH/REQUEST_LOGIN_LINK_FAILURE',

  RESET = 'AUTH/RESET',

  INIT = 'AUTH/INIT',
  INIT_SUCCESS = 'AUTH/INIT_SUCCESS',
  INIT_FAILURE = 'AUTH/INIT_FAILURE',

  REFRESH = 'AUTH/REFRESH',
  REFRESH_SUCCESS = 'AUTH/REFRESH_SUCCESS',
  REFRESH_FAILURE = 'AUTH/REFRESH_FAILURE',

  LOGOUT = 'AUTH/LOGOUT',
  LOGOUT_SUCCESS = 'AUTH/LOGOUT_SUCCESS',
  LOGOUT_FAILURE = 'AUTH/LOGOUT_FAILURE',
}
