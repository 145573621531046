import { Button } from '@components/index';

export type CustomerPreviewProps = {
  customer: ICustomer;
  handleEdit: VoidFunction;
};

export const CustomerPreview = ({ customer, handleEdit }: CustomerPreviewProps) => {
  return (
    <div>
      <div className="flex flex-col items-start mb-[3rem]">
        <p className="font-bold text-[2rem]">
          {customer.firstName} {customer.lastName}
        </p>
        <p>{customer.email}</p>
      </div>
      {/* <Button variant="outline" size="small" onClick={handleEdit}>
        Edit
      </Button> */}
    </div>
  );
};
