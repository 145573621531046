import { AuthAction } from './actionTypes';
import { AuthActions, AuthState } from './types';

const initialState: AuthState = {
  pending: false,
  data: null,
  error: null,
};

export const authReducer = (
  state = initialState,
  action: AuthActions | { type: 'persist/REHYDRATE'; payload: { auth: AuthState } },
) => {
  switch (action.type) {
    case AuthAction.REQUEST_LOGIN_LINK:
      return {
        ...state,
        pending: true,
      };
    case AuthAction.REQUEST_LOGIN_LINK_SUCCESS:
      return {
        pending: false,
        data: null,
        error: null,
      };
    case AuthAction.REQUEST_LOGIN_LINK_FAILURE:
      return {
        pending: false,
        data: null,
        error: action.payload.error,
      };
    case AuthAction.RESET:
      return {
        pending: false,
        data: null,
        error: null,
      };
    case AuthAction.INIT:
      return {
        ...state,
        pending: true,
      };
    case AuthAction.INIT_SUCCESS:
      return {
        pending: false,
        data: action.payload,
        error: null,
      };
    case AuthAction.INIT_FAILURE:
      return {
        pending: false,
        data: null,
        error: action.payload.error,
      };
    case AuthAction.REFRESH:
      return {
        ...state,
        pending: true,
      };
    case AuthAction.REFRESH_SUCCESS:
      return {
        ...state,
        pending: false,
        data: { ...state.data, ...action.payload },
        error: null,
      };
    case AuthAction.REFRESH_FAILURE:
      return {
        pending: false,
        data: null,
        error: action.payload.error,
      };
    case AuthAction.LOGOUT:
      return {
        ...state,
        pending: true,
      };
    case AuthAction.LOGOUT_SUCCESS:
      return {
        pending: false,
        data: null,
        error: null,
      };
    case AuthAction.LOGOUT_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };

    // TODO: improve - remove custom check
    case 'persist/REHYDRATE':
      return {
        ...state,
        data:
          action.payload && action.payload.auth && action.payload.auth?.data
            ? action.payload.auth?.data
            : null,
      };
    default:
      return {
        ...state,
      };
  }
};
