import { toAbsoluteUrl } from '@helpers/index';

export const NoRewardBanner = () => {
  return (
    <div className="reward-banner no-reward-banner text-white rounded-[3rem] lg:rounded-tr-[0] lg:rounded-br-[0] relative">
      <div className="flex gap-[2rem] items-center md:items-end">
        <div className="w-1/2 lg:w-auto">
          <h2 className="reward-banner-title min-w-[22rem]">DEINE BELOHNUNGEN!</h2>
          <p className="reward-banner-message">
            Aufgepasst, hier findest du <b>schon bald Belohnungen für deine Treue </b>bei HelloBello
          </p>
        </div>

        <div className="w-1/2 no-reward-banner-image lg:w-auto">
          <img
            alt="dog-searching-discount"
            src={toAbsoluteUrl('/media/svg/reward/dog-searching-reward.png')}
            className="w-[19rem] pt-[1.5rem]"
          />
        </div>
      </div>

      <span className="reward-banner-fade no-reward-banner-fade ">Geschenkkarte</span>
    </div>
  );
};
