import { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useClickOutside } from 'src/shared/hooks/useClickOutside';
import { KTSVG, toAbsoluteUrl } from '@helpers/index';
import {
  getPaymentDetailsSelector,
  getPaymentDetailsSelectorById,
} from '@modules/payment/core/selectors';
import { getDefaultSubscriptionSelector } from '@modules/subscriptions/core/selectors';
import { changePaymentMethodRequest } from '@modules/subscriptions/core/actions';
import { Dropdown } from '@layout/components/aside/dropdown/Dropdown';
import { DropdownItem } from '@layout/components/aside/dropdown/DropdownItem';
import { Payments } from '@components/payment/Payments';

export const SubscriptionReactivationModalPayment = () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);

  const paymentMethods = useSelector(getPaymentDetailsSelector);
  const defaultSubscription = useSelector(getDefaultSubscriptionSelector);
  const paymentDetails = useSelector(
    getPaymentDetailsSelectorById(defaultSubscription?.paymentMethodId),
  );

  useClickOutside(ref, () => setIsOpen(false));

  const handleOpen = (event: React.MouseEvent) => {
    event.stopPropagation();
    setIsOpen((prev) => !prev);
  };

  const handlePayment = (newPaymentMethodId: number) => {
    dispatch(
      changePaymentMethodRequest({
        id: defaultSubscription?.id,
        params: {
          paymentMethodId: newPaymentMethodId,
        },
      }),
    );
    setIsOpen(false);
  };

  return (
    <div className="subscription-reactivation-modal-payment">
      <div className="z-10 payment-active">
        <KTSVG
          path={toAbsoluteUrl(
            `/media/svg/payment/${paymentDetails?.card?.brand?.toLowerCase()}.svg`,
          )}
        />
      </div>
      <div className="max-w-[27.1rem] pl-[1.9rem] md:pl-0">
        <Payments />
        <div ref={ref} className="relative">
          <button className="subscription-reactivation-modal-payment-dropdown" onClick={handleOpen}>
            <span>Zahlungsart auswählen</span>
            <span className={`${!isOpen ? '' : 'rotate-180'} transition-transform`}>
              <KTSVG path={toAbsoluteUrl('/media/svg/general/down-arrow.svg')} />
            </span>
          </button>

          {isOpen && (
            <div className="z-50 w-full menu-panel menu-panel-payment menu-panel-modal-payment">
              <Dropdown>
                {paymentMethods?.map((payment: any) => (
                  <DropdownItem
                    key={payment?.id}
                    onClick={() => handlePayment(payment?.id)}
                    title={
                      payment?.payPal
                        ? `PayPal ${payment?.payPal?.email}`
                        : `${payment?.card?.brand} **** ${payment?.card?.lastDigits}`
                    }
                    isActive={payment?.id === paymentDetails?.id}
                  />
                ))}
              </Dropdown>
              <Link to="/profile">
                <div className="payment-add-payment">+ Zahlungsmethode hinzufügen</div>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
