import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { authInit } from '../core/actions';
import { useQuery } from 'src/shared';
import { LoginWrapper } from './LoginWrapper';
import { Loading } from '@components/index';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getAuthTokenSelector, getErrorSelector } from '../core/selectors';

export const LoginViaSession = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const error = useSelector(getErrorSelector);
  const accessToken = useSelector(getAuthTokenSelector);

  const { getQueryParam } = useQuery();
  const sessionToken = getQueryParam('sessionToken');

  useEffect(() => {
    if (sessionToken) {
      dispatch(authInit({ sessionToken }));
      localStorage.setItem('auth.sessionToken', sessionToken);
    }
  }, [sessionToken, dispatch]);

  useEffect(() => {
    if (error) navigate(!accessToken ? '/login' : '/');
  }, [error, navigate, accessToken]);

  return (
    <LoginWrapper>
      <h1>
        <span className="login-msg">Wilkommen in deinem</span>
        <span className="login-msg-sup">Pfotenportal</span>
      </h1>

      <p className="login-text">We will log you in... redirecting..</p>
      <Loading />
    </LoginWrapper>
  );
};
