import axios, { AxiosResponse } from 'axios';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { notifySuccess, notifyError } from 'src/helpers/handleNotification';

import {
  fetchSubscriptionsFailure,
  fetchSubscriptionsSuccess,
  updateSubscriptionAddressFailure,
  updateSubscriptionAddressRequest,
  updateSubscriptionAddressSuccess,
  cancelSubscriptionFailure,
  cancelSubscriptionRequest,
  cancelSubscriptionSuccess,
  fetchCancellationFlowFailure,
  fetchCancellationFlowRequest,
  fetchCancellationFlowSuccess,
  pauseSubscriptionFailure,
  pauseSubscriptionRequest,
  pauseSubscriptionSuccess,
  resumeSubscriptionFailure,
  resumeSubscriptionRequest,
  resumeSubscriptionSuccess,
  reactivateSubscriptionFailure,
  reactivateSubscriptionRequest,
  reactivateSubscriptionSuccess,
  updateSubscriptionNextChargeDateFailure,
  updateSubscriptionNextChargeDateRequest,
  updateSubscriptionNextChargeDateSuccess,
  rescheduleOrderRequest,
  rescheduleOrderSuccess,
  rescheduleOrderFailure,
  placeOrderRequest,
  placeOrderSuccess,
  placeOrderFailure,
  skipNextOrderRequest,
  skipNextOrderSuccess,
  skipNextOrderFailure,
  changePaymentMethodRequest,
  changePaymentMethodSuccess,
  changePaymentMethodFailure,
  addDiscountRequest,
  addDiscountSuccess,
  addDiscountFailure,
  removeDiscountRequest,
  removeDiscountSuccess,
  removeDiscountFailure,
  swapLineRequest,
  swapLineSuccess,
  swapLineFailure,
  fetchFlowRewardBannerFailure,
  fetchFlowRewardBannerRequest,
  fetchFlowRewardBannerSuccess,
  fetchBenefitContentFailure,
  fetchBenefitContentRequest,
  fetchBenefitContentSuccess,
  applyBenefitRequest,
  applyBenefitSuccess,
  applyBenefitFailure,
  fetchCancellationOfferRequest,
  fetchCancellationOfferFailure,
  fetchCancellationOfferSuccess,
  applyCancellationOfferRequest,
  applyCancellationOfferFailure,
  applyCancellationOfferSuccess,
} from './actions';
import {
  FETCH_SUBSCRIPTIONS_REQUEST,
  UPDATE_SUBSCRIPTION_ADDRESS_REQUEST,
  CANCEL_SUBSCRIPTION_REQUEST,
  FETCH_CANCELLATION_FLOW_REQUEST,
  PAUSE_SUBSCRIPTION_REQUEST,
  RESUME_SUBSCRIPTION_REQUEST,
  REACTIVATE_SUBSCRIPTION_REQUEST,
  UPDATE_SUBSCRIPTION_NEXT_CHARGE_DATE_REQUEST,
  RESCHEDULE_ORDER_REQUEST,
  PLACE_ORDER_REQUEST,
  SKIP_NEXT_ORDER_REQUEST,
  CHANGE_PAYMENT_METHOD_REQUEST,
  ADD_DISCOUNT_REQUEST,
  REMOVE_DISCOUNT_REQUEST,
  SWAP_LINE_REQUEST,
  FETCH_FLOW_REWARD_BANNER_REQUEST,
  FETCH_BENEFIT_CONTENT_REQUEST,
  APPLY_BENEFIT_REQUEST,
  FETCH_CANCELLATION_OFFER_REQUEST,
  APPLY_CANCELLATION_OFFER_REQUEST,
} from './actionTypes';
import {
  CancelSubscriptionParams,
  SwapLineParams,
  PauseSubscriptionParams,
  ApplyBenefitParams,
  ApplyCancellationOfferParams,
} from './types';
import { SubscriptionError } from 'src/app/types/types';

const getSubscriptions = () => axios.get<ISubscription[]>(`/api/v1/subscriptions`);

const updateSubscriptionAddress = async (id: number, params: any) => {
  const response = await axios.post<ISubscription>(
    `/api/v1/subscriptions/${id}/update_address`,
    params,
  );
  return response.data;
};

const cancelSubscription = async (id: number, params: CancelSubscriptionParams) => {
  const response = await axios.post<ISubscription>(`/api/v1/subscriptions/${id}/cancel`, params);
  return response.data;
};

const fetchCancellationFlow = async (id: number) => {
  const response = await axios.post<ISubscription>(`/api/v1/subscriptions/${id}/cancellationFlow`);
  return response.data;
};

const pauseSubscription = async (id: number, params: PauseSubscriptionParams) => {
  const response = await axios.post<ISubscription>(`/api/v1/subscriptions/${id}/pause`, params);
  return response.data;
};

const resumeSubscription = async (id: number) => {
  const response = await axios.post<ISubscription>(`/api/v1/subscriptions/${id}/resume`);
  return response.data;
};

const reactivateSubscription = async (id: number) => {
  const response = await axios.post<ISubscription>(`/api/v1/subscriptions/${id}/reactivate`);
  return response.data;
};

const updateSubscriptionNextChargeDate = async (id: number, params: any) => {
  const response = await axios.post<ISubscription>(
    `/api/v1/subscriptions/${id}/next_charge_date`,
    params,
  );
  return response.data;
};

const rescheduleOrder = async (subscriptionId: number, params: any) => {
  const response = await axios.post<any>(
    `/api/v1/subscriptions/${subscriptionId}/reschedule`,
    params,
  );
  return response.data;
};

const placeOrder = async (subscriptionId: number) => {
  const response = await axios.post<any>(`/api/v1/subscriptions/${subscriptionId}/place_order`);
  return response.data;
};

const skipNextOrder = async (subscriptionId: number) => {
  const response = await axios.post<any>(`/api/v1/subscriptions/${subscriptionId}/skip_next_order`);
  return response.data;
};

const changePaymentMethod = async (subscriptionId: number, params: any) => {
  const response = await axios.put<ISubscription>(
    `/api/v1/subscriptions/${subscriptionId}/change_payment_method`,
    params,
  );
  return response.data;
};

const addDiscount = async (id: number, params: any) => {
  const response = await axios.post<any>(`/api/v1/subscriptions/${id}/add_discount`, params);
  return response.data;
};

const removeDiscount = async (id: number, params: any) => {
  const response = await axios.delete<any>(
    `/api/v1/subscriptions/${id}/remove_discount/${params?.discountShopifyId}`,
  );
  return response.data;
};

const swapLine = async (id: number, params: SwapLineParams) => {
  const response = await axios.put<ISubscription>(`/api/v1/subscriptions/${id}/swap`, params);
  return response.data;
};

const fetchFlowRewardBanner = async (id: number) => {
  const response = await axios.get<any>(`/api/v1/subscriptions/${id}/flow_reward_banner`);
  return response.data;
};

const fetchBenefitContent = async (id: number) => {
  const response = await axios.get<ISubscription>(`/api/v1/subscriptions/${id}/cancellationFlow`);
  return response.data;
};

const applyBenefit = async (id: number, params: ApplyBenefitParams) => {
  const response = await axios.post<ISubscription>(
    `/api/v1/subscriptions/${id}/apply_benefit`,
    params,
  );
  return response.data;
};

const fetchCancellationOffer = async (subscriptionId: number, cancellationFlowId: number) => {
  const response = await axios.get<ISubscription>(
    `/api/v1/subscriptions/${subscriptionId}/cancellation_offer/${cancellationFlowId}`,
  );
  return response.data;
};

const applyCancellationOffer = async (id: number, params: ApplyCancellationOfferParams) => {
  const response = await axios.post<ISubscription>(
    `/api/v1/subscriptions/${id}/apply_cancellation_offer`,
    params,
  );
  return response.data;
};

function* fetchSubscriptionsSaga() {
  try {
    const response: AxiosResponse<ISubscription[]> = yield call(getSubscriptions);

    yield put(
      fetchSubscriptionsSuccess({
        subscriptions: response.data,
      }),
    );
  } catch (e: any) {
    yield put(
      fetchSubscriptionsFailure({
        error: e.message,
      }),
    );
  }
}

function* updateSubscriptionAddressSaga(
  action: ReturnType<typeof updateSubscriptionAddressRequest>,
) {
  try {
    const response: AxiosResponse<ISubscription> = yield call(() =>
      updateSubscriptionAddress(action.payload.id, action.payload.params),
    );

    yield put(
      updateSubscriptionAddressSuccess({
        subscription: { ...response.data, id: action.payload.id },
      }),
    );
    notifySuccess('Versandadresse Aktualisiert');
  } catch (e: any) {
    yield put(
      updateSubscriptionAddressFailure({
        error: e.message,
      }),
    );
    notifyError('Es ist ein Fehler aufgetreten');
  }
}

function* cancelSubscriptionSaga(action: ReturnType<typeof cancelSubscriptionRequest>) {
  try {
    const response: AxiosResponse<ISubscription> = yield call(() =>
      cancelSubscription(action.payload.id, action.payload.params),
    );

    yield put(
      cancelSubscriptionSuccess({
        // TODO: remove after api ready
        subscription: { ...response?.data, id: action.payload.id },
      }),
    );
    notifySuccess('Dein Abo wurde gekündigt');
  } catch (e: any) {
    yield put(
      cancelSubscriptionFailure({
        error: e.message,
      }),
    );
    notifyError('Abo konnte nicht gekündigt werden');
  }
}

function* fetchCancellationFlowSaga(action: ReturnType<typeof fetchCancellationFlowRequest>) {
  try {
    const response: AxiosResponse<ISubscription> = yield call(() =>
      fetchCancellationFlow(action.payload.id),
    );

    yield put(
      fetchCancellationFlowSuccess({
        subscription: response.data,
      }),
    );
  } catch (e: any) {
    yield put(
      fetchCancellationFlowFailure({
        error: e.message,
      }),
    );
  }
}

function* pauseSubscriptionSaga(action: ReturnType<typeof pauseSubscriptionRequest>) {
  try {
    const response: AxiosResponse<ISubscription> = yield call(() =>
      pauseSubscription(action.payload.id, action.payload.params),
    );

    yield put(
      pauseSubscriptionSuccess({
        subscription: response.data,
      }),
    );
    notifySuccess('Dein Abo wurde pausiert');
  } catch (e: any) {
    yield put(
      pauseSubscriptionFailure({
        error: e.message,
      }),
    );
    notifyError('Abo konnte nicht pausiert werden');
  }
}

function* resumeSubscriptionSaga(action: ReturnType<typeof resumeSubscriptionRequest>) {
  try {
    const response: AxiosResponse<ISubscription> = yield call(() =>
      resumeSubscription(action.payload.id),
    );

    yield put(
      resumeSubscriptionSuccess({
        subscription: response.data,
      }),
    );
    notifySuccess('Dein Abo wurde fortgesetzt');
  } catch (e: any) {
    yield put(
      resumeSubscriptionFailure({
        error: e.message,
      }),
    );
    notifyError('Abo konnte nicht fortgesetzt werden');
  }
}

function* reactivateSubscriptionSaga(action: ReturnType<typeof reactivateSubscriptionRequest>) {
  try {
    const response: AxiosResponse<ISubscription> = yield call(() =>
      reactivateSubscription(action.payload.id),
    );

    yield put(
      reactivateSubscriptionSuccess({
        // TODO: remove after api ready
        subscription: { ...response?.data, id: action.payload.id },
      }),
    );
    notifySuccess('Dein Abo wurde reaktiviert');
  } catch (e: any) {
    yield put(
      reactivateSubscriptionFailure({
        error: e.message,
      }),
    );
    notifyError('Abo konnte nicht reaktiviert werden');
  }
}

function* updateSubscriptionNextChargeDateSaga(
  action: ReturnType<typeof updateSubscriptionNextChargeDateRequest>,
) {
  try {
    const response: AxiosResponse<ISubscription> = yield call(() =>
      updateSubscriptionNextChargeDate(action.payload.id, action.payload.params),
    );

    yield put(
      updateSubscriptionNextChargeDateSuccess({
        subscription: response.data,
      }),
    );
  } catch (e: any) {
    yield put(
      updateSubscriptionNextChargeDateFailure({
        error: e.message,
      }),
    );
    notifyError('Es ist ein Fehler aufgetreten');
  }
}

function* rescheduleOrderSaga(action: ReturnType<typeof rescheduleOrderRequest>) {
  try {
    const response: AxiosResponse<any> = yield call(() =>
      rescheduleOrder(action.payload.id, action.payload.params),
    );

    yield put(
      rescheduleOrderSuccess({
        subscription: response.data,
      }),
    );
    notifySuccess('Bestelldatum geändert. E-mail folgt');
  } catch (e: any) {
    yield put(
      rescheduleOrderFailure({
        error: e.message,
      }),
    );
    notifyError('Es ist ein Fehler aufgetreten');
  }
}

function* placeOrderSaga(action: ReturnType<typeof placeOrderRequest>) {
  try {
    const response: AxiosResponse<any> = yield call(() => placeOrder(action.payload.id));

    yield put(
      placeOrderSuccess({
        subscription: response.data,
      }),
    );
    notifySuccess('Bestellung ausgelöst. E-mail folgt');
  } catch (e: any) {
    yield put(
      placeOrderFailure({
        error: e.message,
      }),
    );
    notifyError('Bestellung konnte nicht erstellt werden');
  }
}

function* skipNextOrderSaga(action: ReturnType<typeof skipNextOrderRequest>) {
  try {
    const response: AxiosResponse<any> = yield call(() => skipNextOrder(action.payload.id));

    yield put(
      skipNextOrderSuccess({
        subscription: response.data,
      }),
    );
    notifySuccess('Bestellung übersprungen. E-mail folgt');
  } catch (e: any) {
    yield put(
      skipNextOrderFailure({
        error: e.message,
      }),
    );
    notifyError('Bestellung konnte nicht übersprungen werden');
  }
}

function* changePaymentMethodSaga(action: ReturnType<typeof changePaymentMethodRequest>) {
  try {
    const response: AxiosResponse<ISubscription> = yield call(() =>
      changePaymentMethod(action.payload.id, action.payload.params),
    );

    yield put(
      changePaymentMethodSuccess({
        subscription: response.data,
      }),
    );
    notifySuccess('Zahlungsdaten aktualisiert');
  } catch (e: any) {
    yield put(
      changePaymentMethodFailure({
        error: e.message,
      }),
    );
    notifyError('Es ist ein Fehler aufgetreten');
  }
}

function* addDiscountSaga(action: ReturnType<typeof addDiscountRequest>) {
  try {
    const response: AxiosResponse<any> = yield call(() =>
      addDiscount(action.payload.id, action.payload.params),
    );

    yield put(
      addDiscountSuccess({
        subscription: response.data,
      }),
    );
    notifySuccess('Rabatt erfolgreich angewendet');
  } catch (e: any) {
    yield put(
      addDiscountFailure({
        error: e.message,
      }),
    );
    notifyError('Rabatt konnte nicht hinzugefügt werden');
  }
}

function* removeDiscountSaga(action: ReturnType<typeof removeDiscountRequest>) {
  try {
    const response: AxiosResponse<any> = yield call(() =>
      removeDiscount(action.payload.id, action.payload.params),
    );

    yield put(
      removeDiscountSuccess({
        subscription: response.data,
      }),
    );
    notifySuccess('Rabatt entfernt');
  } catch (e: any) {
    yield put(
      removeDiscountFailure({
        error: e.message,
      }),
    );
    notifyError('Rabatt konnte nicht entfernt werden');
  }
}

function* swapLineSaga(action: ReturnType<typeof swapLineRequest>) {
  try {
    const response: AxiosResponse<any> = yield call(() =>
      swapLine(action.payload.id, action.payload.params),
    );

    yield put(
      swapLineSuccess({
        id: action.payload.id,
        lineItem: response.data,
        billingPolicy: {
          interval: 'WEEK',
          intervalCount: action.payload.params.billingPolicy.intervalCount / 7,
        },
      }),
    );
    notifySuccess('Abo erfolgreich geändert');
  } catch (e: any) {
    yield put(
      swapLineFailure({
        error: e.message,
      }),
    );
    notifyError('Es ist ein Fehler aufgetreten');
  }
}

function* fetchFlowRewardBannerSaga(action: ReturnType<typeof fetchFlowRewardBannerRequest>) {
  try {
    const response: AxiosResponse<any> = yield call(() =>
      fetchFlowRewardBanner(action.payload.subscriptionId),
    );

    yield put(
      fetchFlowRewardBannerSuccess({ id: action.payload.subscriptionId, data: response.data }),
    );
  } catch (e: any) {
    yield put(
      fetchFlowRewardBannerFailure({
        error: {
          type: SubscriptionError.FLOW_BANNER_NOT_FOUND,
          message: e.message,
        },
      }),
    );
  }
}

function* fetchBenefitContentSaga(action: ReturnType<typeof fetchBenefitContentRequest>) {
  try {
    const response: AxiosResponse<ISubscription> = yield call(() =>
      fetchBenefitContent(action.payload.id),
    );

    yield put(
      fetchBenefitContentSuccess({
        subscription: response.data,
      }),
    );
  } catch (e: any) {
    yield put(
      fetchBenefitContentFailure({
        error: e.message,
      }),
    );
  }
}

function* applyBenefitSaga(action: ReturnType<typeof applyBenefitRequest>) {
  try {
    const response: AxiosResponse<ISubscription> = yield call(() =>
      applyBenefit(action.payload.id, action.payload.params),
    );

    yield put(
      applyBenefitSuccess({
        subscription: { ...response?.data, id: action.payload.id },
      }),
    );
  } catch (e: any) {
    yield put(
      applyBenefitFailure({
        error: e.message,
      }),
    );
  }
}

function* fetchCancellationOfferSaga(action: ReturnType<typeof fetchCancellationOfferRequest>) {
  try {
    const response: AxiosResponse<ISubscription> = yield call(() =>
      fetchCancellationOffer(action.payload.subscriptionId, action.payload.cancellationFlowId),
    );

    yield put(
      fetchCancellationOfferSuccess({
        id: action.payload.subscriptionId,
        data: response.data,
        cancellationFlowId: action.payload.cancellationFlowId,
      }),
    );
  } catch (e: any) {
    yield put(
      fetchCancellationOfferFailure({
        error: e.message,
      }),
    );
  }
}

function* applyCancellationOfferSaga(action: ReturnType<typeof applyCancellationOfferRequest>) {
  try {
    const response: AxiosResponse<ISubscription> = yield call(() =>
      applyCancellationOffer(action.payload.id, action.payload.params),
    );

    yield put(
      applyCancellationOfferSuccess({
        id: action.payload.id,
        data: response.data,
      }),
    );
  } catch (e: any) {
    yield put(
      applyCancellationOfferFailure({
        error: e.message,
      }),
    );
  }
}

export function* subscriptionsSaga() {
  yield all([
    takeLatest(FETCH_SUBSCRIPTIONS_REQUEST, fetchSubscriptionsSaga),
    takeLatest(UPDATE_SUBSCRIPTION_ADDRESS_REQUEST, updateSubscriptionAddressSaga),
    takeLatest(CANCEL_SUBSCRIPTION_REQUEST, cancelSubscriptionSaga),
    takeLatest(FETCH_CANCELLATION_FLOW_REQUEST, fetchCancellationFlowSaga),
    takeLatest(PAUSE_SUBSCRIPTION_REQUEST, pauseSubscriptionSaga),
    takeLatest(RESUME_SUBSCRIPTION_REQUEST, resumeSubscriptionSaga),
    takeLatest(REACTIVATE_SUBSCRIPTION_REQUEST, reactivateSubscriptionSaga),
    takeLatest(UPDATE_SUBSCRIPTION_NEXT_CHARGE_DATE_REQUEST, updateSubscriptionNextChargeDateSaga),
    takeLatest(RESCHEDULE_ORDER_REQUEST, rescheduleOrderSaga),
    takeLatest(PLACE_ORDER_REQUEST, placeOrderSaga),
    takeLatest(SKIP_NEXT_ORDER_REQUEST, skipNextOrderSaga),
    takeLatest(CHANGE_PAYMENT_METHOD_REQUEST, changePaymentMethodSaga),
    takeLatest(ADD_DISCOUNT_REQUEST, addDiscountSaga),
    takeLatest(REMOVE_DISCOUNT_REQUEST, removeDiscountSaga),
    takeLatest(SWAP_LINE_REQUEST, swapLineSaga),
    takeLatest(FETCH_FLOW_REWARD_BANNER_REQUEST, fetchFlowRewardBannerSaga),
    takeLatest(FETCH_BENEFIT_CONTENT_REQUEST, fetchBenefitContentSaga),
    takeLatest(APPLY_BENEFIT_REQUEST, applyBenefitSaga),
    takeLatest(FETCH_CANCELLATION_OFFER_REQUEST, fetchCancellationOfferSaga),
    takeLatest(APPLY_CANCELLATION_OFFER_REQUEST, applyCancellationOfferSaga),
  ]);
}
