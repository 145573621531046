import isEqual from 'lodash/isEqual';
import { createSelector } from 'reselect';
import { RECIPES } from '@constants/subscription';
import { RootState } from 'src/store/store';
import { getDisabledRecipes } from '../utils/getDisabledRecipes';
import { getUpdatedSubscriptionOptions } from '@modules/settings/core/selectors';
import { stripHtmlTags } from 'src/app/utils/stripHtmlTags';
import { skuLeckerliesRegex } from '@constants/common';

const getSettings = (state: RootState) => state.settings.data;

const getPending = (state: RootState) => state.subscriptions.pending;

const getSubscriptions = (state: RootState) => state.subscriptions.data;

const getError = (state: RootState) => state.subscriptions.error;

export const getPendingSelector = createSelector(getPending, (pending) => pending);

export const getErrorSelector = createSelector(getError, (error) =>
  typeof error === 'string' ? error : null,
);

export const getFlowBannerErrorSelector = createSelector(getError, (error) =>
  error.type === 'FLOW_BANNER_NOT_FOUND' ? error.message : null,
);

export const getSubscriptionsSelector = createSelector(getSubscriptions, (subscriptions) =>
  subscriptions.filter(
    (subscription: ISubscription) =>
      subscription.lines?.some((lineItem: any) => lineItem.product !== null) &&
      subscription.pet !== null,
  ),
);

export const getCancelledSubscriptionsSelector = createSelector(getSubscriptions, (subscriptions) =>
  subscriptions.filter((subscription: ISubscription) => subscription?.status === 'CANCELLED'),
);

export const getDefaultSubscriptionSelector = createSelector(
  [getSubscriptions, getSettings],
  (subscriptions, settings) => {
    return subscriptions?.find(
      (subscription: ISubscription) => subscription?.id === settings.defaultSubscription,
    );
  },
);

export const getPetSelector = createSelector(
  [getDefaultSubscriptionSelector],
  (defaultSubscription) => defaultSubscription?.pet,
);

export const getDiscountSelector = createSelector(
  [getDefaultSubscriptionSelector],
  (defaultSubscription) =>
    defaultSubscription?.discounts?.filter((discount: Discount) => discount.isActive)?.[0],
);

export const getSubscriptionsWithPetsSortedByLF = createSelector(
  [getSubscriptionsSelector],
  (subscriptions) => {
    const sortedSubscriptions = [...subscriptions].sort((subA, subB) =>
      subA?.status === 'ACTIVE' ? -1 : subB?.status === 'ACTIVE' ? 1 : 0,
    );

    return sortedSubscriptions.filter(
      (subscription) => subscription?.pet && subscription?.pet.name,
    );
  },
);

export const getLineItemSelector = createSelector(
  [getDefaultSubscriptionSelector],
  (defaultSubscription) => defaultSubscription?.lines?.[0],
);

export const getLeckerlieSelector = createSelector(
  [getDefaultSubscriptionSelector],
  (defaultSubscription) => {
    return (
      defaultSubscription?.lines?.filter((lineItem: any) =>
        skuLeckerliesRegex.test(lineItem.sku),
      ) || []
    );
  },
);

export const getSubscriptionsShippingAddresses = createSelector(getSubscriptions, (subscriptions) =>
  subscriptions
    .map((subscription: ISubscription) => subscription.shippingAddress)
    .filter(
      (address: IShippingAddress, index: number, self: IShippingAddress[]) =>
        index === self.findIndex((t) => t.address1 === address.address1 && t.zip === address.zip),
    ),
);

export const getDefaultSubscriptionPaymentIdSelector = createSelector(
  [getDefaultSubscriptionSelector],
  (defaultSubscription) => defaultSubscription?.paymentMethodId,
);

export const getSubscriptionCycleSelector = createSelector([getLineItemSelector], (lineItem) => {
  const cycle = lineItem?.sku?.match(/\d{4}-\w*-[H|V]-(14|28|TEST)/);

  return cycle && cycle[1] === 'TEST' ? 14 : parseInt(cycle?.[1] || '14', 10);
});

export const getSubscriptionPensionSelector = createSelector([getLineItemSelector], (lineItem) => {
  const pension = lineItem?.sku?.match(/\d{4}-\w*-([H|V])-[14|28|TEST]/);

  return pension?.[1] || '';
});

export const getSubscriptionRecipesSelector = createSelector([getLineItemSelector], (lineItem) => {
  const recipesMatch = lineItem?.sku?.match(/\d{4}-(\w*)-[H|V]-[14|28|TEST]/);

  return recipesMatch
    ? recipesMatch[1]
        ?.match(/.{1,2}/g)
        ?.map((key: string) => RECIPES[key as keyof typeof RECIPES]) || []
    : [];
});

export const getDisabledRecipesSelector = createSelector([getPetSelector], (pet) =>
  getDisabledRecipes(pet),
);

export const getSubscriptionOptionsSelector = createSelector(
  [getSubscriptionCycleSelector, getSubscriptionPensionSelector, getSubscriptionRecipesSelector],
  (cycle, pension, recipes) => ({
    cycle,
    pension,
    recipes,
  }),
);

export const getIsUpdatedVariantSelector = createSelector(
  [getSubscriptionOptionsSelector, getUpdatedSubscriptionOptions],
  (subscriptionOptions, updatedSubscriptionOptions) =>
    !isEqual(subscriptionOptions, updatedSubscriptionOptions),
);

export const getUpdatedSubscriptionValuesSelector = createSelector(
  [getSubscriptionOptionsSelector, getUpdatedSubscriptionOptions],
  (subscriptionOptions: any, updatedSubscriptionOptions) => {
    const updatedValues: any = {};

    Object.keys(subscriptionOptions).forEach((key) => {
      if (!isEqual(subscriptionOptions[key], updatedSubscriptionOptions?.[key]))
        updatedValues[key] = updatedSubscriptionOptions?.[key];
    });

    return updatedValues;
  },
);

export const getFlowBannerSelector = createSelector(
  [getDefaultSubscriptionSelector],
  (defaultSubscription) => defaultSubscription?.flowBanner,
);
export const getFlowBannerTextSelector = createSelector(
  [getDefaultSubscriptionSelector],
  (defaultSubscription) => stripHtmlTags(defaultSubscription?.flowBanner?.[0]?.text ?? ''),
);

export const getCancellationOfferSelector = createSelector(
  [getDefaultSubscriptionSelector],
  (defaultSubscription) => defaultSubscription?.cancellationOffer,
);

export const getCancellationFlowIdSelector = createSelector(
  [getDefaultSubscriptionSelector],
  (defaultSubscription) => defaultSubscription?.cancellationFlowId,
);
