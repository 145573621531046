import { FormProvider } from 'react-hook-form';
import { ReactNode } from 'react';

export type FormProps = {
  children: ReactNode;
  form: any;
  handleSubmit: any;
  className?: any;
};

export const Form = ({ children, form, handleSubmit, className }: FormProps) => {
  return (
    <>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)} {...(className && { className })}>
          {children}
        </form>
      </FormProvider>
    </>
  );
};
