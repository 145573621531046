import React, { useRef, useState } from 'react';
import { FormattedDate } from 'react-intl';
import { useSelector } from 'react-redux';
import { getVariantProductInfoSelector } from '@modules/products/core/selectors';
import { getPetSelector } from '@modules/subscriptions/core/selectors';
import { useClickOutside } from 'src/shared';

import { calcDeliveryDate } from '@modules/subscriptions/utils/calcDeliveryDate';
import { SubscriptionReactivationOrderItems } from './SubscriptionReactivationOrderItems';
import { SubscriptionReactivationRecipes } from './SubscriptionReactivationRecipes';
import { SubscriptionReactivationModal } from './reactivation-modal/SubscriptionReactivationModal';
import { Modal } from '@components/index';
import { KTSVG, toAbsoluteUrl } from '@helpers/index';

interface SubscriptionReactivationInfoProps {
  intro?: string;
  text?: string;
  textExtra?: string;
  buttonText: string;
  onReactivateClick: () => void;
}

export const SubscriptionReactivationInfo: React.FC<SubscriptionReactivationInfoProps> = ({
  intro,
  text,
  textExtra,
  buttonText,
  onReactivateClick,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);

  const pet = useSelector(getPetSelector);
  const productInfo = useSelector(getVariantProductInfoSelector);

  const totalSachetQuantity = productInfo?.sachets?.reduce(
    (total: any, sachet: any) => total + sachet.quantity,
    0,
  );
  const today = new Date().toISOString();
  const [dateFrom, dateTo] = calcDeliveryDate(today);

  useClickOutside(ref, () => {
    setIsModalOpen(false);
  });

  const handleClose = () => setIsModalOpen(false);

  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div className="subscription-reactivation-content">
      <h4 className="subscription-reactivation-order-headline px-[3rem] hidden md:block">
        {pet?.name}'s Letzte Bestellübersicht
      </h4>
      <div className="subscription-reactivation-text">
        <p>{intro}</p>
        <div className="subscription-reactivation-text-description">{text}</div>

        <span>{textExtra}</span>
      </div>

      <div className="subscription-reactivation-order">
        <h4 className="subscription-reactivation-order-headline md:hidden">
          {pet?.name}'s Letzte Bestellübersicht
        </h4>

        <div className="subscription-reactivation-action md:hidden">
          <button className="flex items-center gap-2" onClick={handleModalToggle}>
            <KTSVG path={toAbsoluteUrl('/media/svg/general/edit.svg')} />
            Versand & Zahlung
          </button>
          {isModalOpen && (
            <Modal isOpen={true} handleClose={handleClose}>
              <SubscriptionReactivationModal />
            </Modal>
          )}
        </div>

        <div className="subscription-reactivation-calendar md:hidden">
          <button className="subscription-reactivation-calendar-date">
            <p className="">Bestelldatum</p>

            <KTSVG path={toAbsoluteUrl('/media/svg/general/edit.svg')} />
            <FormattedDate value={today} year="numeric" month="short" day="2-digit" />
            <span className="-rotate-90">
              <KTSVG path={toAbsoluteUrl('/media/svg/general/down-arrow.svg')} />
            </span>
          </button>
          <button className="subscription-reactivation-calendar-delivery">
            <p className="">Lieferdatum</p>
            <FormattedDate value={dateFrom} day="2-digit" /> -{' '}
            <FormattedDate value={dateTo} year="numeric" month="short" day="2-digit" />
          </button>
        </div>

        <SubscriptionReactivationOrderItems totalSachetQuantity={totalSachetQuantity} />

        <SubscriptionReactivationRecipes />

        <button className="subscription-reactivation-button" onClick={onReactivateClick}>
          {buttonText}
        </button>
      </div>
    </div>
  );
};
