import {
  FETCH_ORDER_SCHEDULE_REQUEST,
  FETCH_ORDER_SCHEDULE_FAILURE,
  FETCH_ORDER_SCHEDULE_SUCCESS,
  SKIP_ORDER_REQUEST,
  SKIP_ORDER_SUCCESS,
  SKIP_ORDER_FAILURE,
  UNSKIP_ORDER_REQUEST,
  UNSKIP_ORDER_SUCCESS,
  UNSKIP_ORDER_FAILURE,
} from './actionTypes';
import {
  FetchOrderScheduleRequest,
  FetchOrderScheduleSuccess,
  FetchOrderScheduleSuccessPayload,
  FetchOrderScheduleFailure,
  FetchOrderScheduleFailurePayload,
  FetchOrderScheduleRequestPayload,
  SkipOrderRequest,
  SkipOrderRequestPayload,
  SkipOrderSuccess,
  SkipOrderSuccessPayload,
  SkipOrderFailure,
  SkipOrderFailurePayload,
  UnskipOrderRequest,
  UnskipOrderRequestPayload,
  UnskipOrderSuccess,
  UnskipOrderSuccessPayload,
  UnskipOrderFailure,
  UnskipOrderFailurePayload,
} from './types';

export const fetchOrderScheduleRequest = (
  payload: FetchOrderScheduleRequestPayload,
): FetchOrderScheduleRequest => ({
  type: FETCH_ORDER_SCHEDULE_REQUEST,
  payload,
});

export const fetchOrderScheduleSuccess = (
  payload: FetchOrderScheduleSuccessPayload,
): FetchOrderScheduleSuccess => ({
  type: FETCH_ORDER_SCHEDULE_SUCCESS,
  payload,
});

export const fetchOrderScheduleFailure = (
  payload: FetchOrderScheduleFailurePayload,
): FetchOrderScheduleFailure => ({
  type: FETCH_ORDER_SCHEDULE_FAILURE,
  payload,
});

export const skipOrderRequest = (payload: SkipOrderRequestPayload): SkipOrderRequest => ({
  type: SKIP_ORDER_REQUEST,
  payload,
});

export const skipOrderSuccess = (payload: SkipOrderSuccessPayload): SkipOrderSuccess => ({
  type: SKIP_ORDER_SUCCESS,
  payload,
});

export const skipOrderFailure = (payload: SkipOrderFailurePayload): SkipOrderFailure => ({
  type: SKIP_ORDER_FAILURE,
  payload,
});

export const unskipOrderRequest = (payload: UnskipOrderRequestPayload): UnskipOrderRequest => ({
  type: UNSKIP_ORDER_REQUEST,
  payload,
});

export const unskipOrderSuccess = (payload: UnskipOrderSuccessPayload): UnskipOrderSuccess => ({
  type: UNSKIP_ORDER_SUCCESS,
  payload,
});

export const unskipOrderFailure = (payload: UnskipOrderFailurePayload): UnskipOrderFailure => ({
  type: UNSKIP_ORDER_FAILURE,
  payload,
});
