import * as API from '@api/types';
import {
  AuthRequestLoginLink,
  AuthRequestLoginLinkSuccess,
  AuthRequestLoginLinkFailure,
  AuthReset,
  AuthInit,
  AuthInitSuccess,
  AuthInitFailure,
  AuthRefresh,
  AuthRefreshSuccess,
  AuthRefreshFailure,
  AuthLogout,
  AuthLogoutSuccess,
  AuthLogoutFailure,
} from './types';
import { AuthAction } from './actionTypes';

export const authRequestLoginLink = (
  payload: API.AuthSendLoginLinkRequest,
): AuthRequestLoginLink => ({
  type: AuthAction.REQUEST_LOGIN_LINK,
  payload,
});

export const authRequestLoginLinkSuccess = (
  payload: API.AuthSendLoginLinkResponse,
): AuthRequestLoginLinkSuccess => ({
  type: AuthAction.REQUEST_LOGIN_LINK_SUCCESS,
  payload,
});

export const authRequestLoginLinkFailure = (
  payload: API.ErrorResponse,
): AuthRequestLoginLinkFailure => ({
  type: AuthAction.REQUEST_LOGIN_LINK_FAILURE,
  payload,
});

export const authResetLoginLinkRequest = (): AuthReset => ({
  type: AuthAction.RESET,
});

export const authInit = (payload: API.AuthInitRequest): AuthInit => ({
  type: AuthAction.INIT,
  payload,
});

export const authInitSuccess = (payload: API.AuthInitResponse): AuthInitSuccess => ({
  type: AuthAction.INIT_SUCCESS,
  payload,
});

export const authInitFailure = (payload: API.ErrorResponse): AuthInitFailure => ({
  type: AuthAction.INIT_FAILURE,
  payload,
});

export const authRefresh = (payload: API.AuthRefreshRequest): AuthRefresh => ({
  type: AuthAction.REFRESH,
  payload,
});

export const authRefreshSuccess = (payload: API.AuthRefreshResponse): AuthRefreshSuccess => ({
  type: AuthAction.REFRESH_SUCCESS,
  payload,
});

export const authRefreshFailure = (payload: API.ErrorResponse): AuthRefreshFailure => ({
  type: AuthAction.REFRESH_FAILURE,
  payload,
});

export const authLogout = (payload: API.AuthLogoutRequest): AuthLogout => ({
  type: AuthAction.LOGOUT,
  payload,
});

export const authLogoutSuccess = (payload: API.AuthLogoutResponse): AuthLogoutSuccess => ({
  type: AuthAction.LOGOUT_SUCCESS,
  payload,
});

export const authLogoutFailure = (payload: API.ErrorResponse): AuthLogoutFailure => ({
  type: AuthAction.LOGOUT_FAILURE,
  payload,
});
