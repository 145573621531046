import {
  FETCH_SUBSCRIPTIONS_REQUEST,
  FETCH_SUBSCRIPTIONS_SUCCESS,
  FETCH_SUBSCRIPTIONS_FAILURE,
  UPDATE_SUBSCRIPTION_ADDRESS_REQUEST,
  UPDATE_SUBSCRIPTION_ADDRESS_SUCCESS,
  UPDATE_SUBSCRIPTION_ADDRESS_FAILURE,
  CANCEL_SUBSCRIPTION_REQUEST,
  CANCEL_SUBSCRIPTION_SUCCESS,
  CANCEL_SUBSCRIPTION_FAILURE,
  FETCH_CANCELLATION_FLOW_REQUEST,
  FETCH_CANCELLATION_FLOW_SUCCESS,
  FETCH_CANCELLATION_FLOW_FAILURE,
  PAUSE_SUBSCRIPTION_REQUEST,
  PAUSE_SUBSCRIPTION_SUCCESS,
  PAUSE_SUBSCRIPTION_FAILURE,
  RESUME_SUBSCRIPTION_REQUEST,
  RESUME_SUBSCRIPTION_SUCCESS,
  RESUME_SUBSCRIPTION_FAILURE,
  REACTIVATE_SUBSCRIPTION_REQUEST,
  REACTIVATE_SUBSCRIPTION_SUCCESS,
  REACTIVATE_SUBSCRIPTION_FAILURE,
  UPDATE_SUBSCRIPTION_NEXT_CHARGE_DATE_REQUEST,
  UPDATE_SUBSCRIPTION_NEXT_CHARGE_DATE_SUCCESS,
  UPDATE_SUBSCRIPTION_NEXT_CHARGE_DATE_FAILURE,
  RESCHEDULE_ORDER_REQUEST,
  RESCHEDULE_ORDER_SUCCESS,
  RESCHEDULE_ORDER_FAILURE,
  PLACE_ORDER_REQUEST,
  PLACE_ORDER_SUCCESS,
  PLACE_ORDER_FAILURE,
  SKIP_NEXT_ORDER_REQUEST,
  SKIP_NEXT_ORDER_SUCCESS,
  SKIP_NEXT_ORDER_FAILURE,
  CHANGE_PAYMENT_METHOD_REQUEST,
  CHANGE_PAYMENT_METHOD_SUCCESS,
  CHANGE_PAYMENT_METHOD_FAILURE,
  ADD_DISCOUNT_REQUEST,
  ADD_DISCOUNT_SUCCESS,
  ADD_DISCOUNT_FAILURE,
  REMOVE_DISCOUNT_REQUEST,
  REMOVE_DISCOUNT_SUCCESS,
  REMOVE_DISCOUNT_FAILURE,
  SWAP_LINE_REQUEST,
  SWAP_LINE_SUCCESS,
  SWAP_LINE_FAILURE,
  FETCH_FLOW_REWARD_BANNER_REQUEST,
  FETCH_FLOW_REWARD_BANNER_SUCCESS,
  FETCH_FLOW_REWARD_BANNER_FAILURE,
  FETCH_BENEFIT_CONTENT_REQUEST,
  FETCH_BENEFIT_CONTENT_SUCCESS,
  FETCH_BENEFIT_CONTENT_FAILURE,
  APPLY_BENEFIT_REQUEST,
  APPLY_BENEFIT_SUCCESS,
  APPLY_BENEFIT_FAILURE,
  FETCH_CANCELLATION_OFFER_REQUEST,
  FETCH_CANCELLATION_OFFER_SUCCESS,
  FETCH_CANCELLATION_OFFER_FAILURE,
  APPLY_CANCELLATION_OFFER_REQUEST,
  APPLY_CANCELLATION_OFFER_SUCCESS,
  APPLY_CANCELLATION_OFFER_FAILURE,
} from './actionTypes';

import { SubscriptionsActions, SubscriptionsState } from './types';

const initialState: SubscriptionsState = {
  pending: false,
  data: [],
  error: null,
};

export const subscriptionsReducer = (state = initialState, action: SubscriptionsActions) => {
  switch (action.type) {
    case FETCH_SUBSCRIPTIONS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_SUBSCRIPTIONS_SUCCESS:
      // const filterSubscriptions = action.payload.subscriptions.data.filter(
      //   (subscription: any) => subscription.pet !== null,
      // );
      return {
        ...state,
        pending: false,
        data: action.payload.subscriptions.data,
        error: null,
      };
    case FETCH_SUBSCRIPTIONS_FAILURE:
      console.warn(action);
      return {
        ...state,
        pending: false,
        data: [],
        error: action.payload.error,
      };
    case UPDATE_SUBSCRIPTION_ADDRESS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case UPDATE_SUBSCRIPTION_ADDRESS_SUCCESS:
      return {
        ...state,
        pending: false,
        data: state.data.map((subscription) => {
          const { id: subscriptionId, ...shippingAddress } = action.payload.subscription;
          return subscription.id === subscriptionId
            ? {
                ...subscription,
                shippingAddress,
              }
            : subscription;
        }),
        error: null,
      };
    case UPDATE_SUBSCRIPTION_ADDRESS_FAILURE:
      return {
        ...state,
        pending: false,
        data: state.data,
        error: action.payload.error,
      };
    case UPDATE_SUBSCRIPTION_NEXT_CHARGE_DATE_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case UPDATE_SUBSCRIPTION_NEXT_CHARGE_DATE_SUCCESS:
      return {
        ...state,
        pending: false,
        data: state.data.map((subscription) =>
          subscription.id === action.payload.subscription.id
            ? {
                ...subscription,
                ...action.payload.subscription,
              }
            : subscription,
        ),
        error: null,
      };
    case UPDATE_SUBSCRIPTION_NEXT_CHARGE_DATE_FAILURE:
      return {
        ...state,
        pending: false,
        data: state.data,
        error: action.payload.error,
      };

    case CANCEL_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case CANCEL_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        pending: false,

        data: state.data.map((subscription) =>
          subscription.id === action.payload.subscription.id
            ? {
                ...subscription,
                ...action.payload.subscription,
              }
            : subscription,
        ),
        error: null,
      };
    case CANCEL_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        pending: false,
        data: state.data,
        error: action.payload.error,
      };

    case FETCH_CANCELLATION_FLOW_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_CANCELLATION_FLOW_SUCCESS:
      return {
        ...state,
        pending: false,
        data: state.data.map((subscription) =>
          subscription.id === action.payload.subscription.data.id
            ? {
                ...subscription,
                ...action.payload.subscription.data,
              }
            : subscription,
        ),
        error: null,
      };
    case FETCH_CANCELLATION_FLOW_FAILURE:
      return {
        ...state,
        pending: false,
        data: state.data,
        error: action.payload.error,
      };

    case PAUSE_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case PAUSE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        pending: false,
        data: state.data.map((subscription) =>
          subscription.id === action.payload.subscription.id
            ? {
                ...subscription,
                ...action.payload.subscription,
              }
            : subscription,
        ),
        error: null,
      };
    case PAUSE_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        pending: false,
        data: state.data,
        error: action.payload.error,
      };

    case RESUME_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case RESUME_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        pending: false,
        data: state.data.map((subscription) =>
          subscription.id === action.payload.subscription.id
            ? {
                ...subscription,
                ...action.payload.subscription,
              }
            : subscription,
        ),
        error: null,
      };
    case RESUME_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        pending: false,
        data: state.data,
        error: action.payload.error,
      };

    case REACTIVATE_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case REACTIVATE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        pending: false,
        data: state.data.map((subscription) =>
          subscription.id === action.payload.subscription.id
            ? {
                ...subscription,
                ...action.payload.subscription,
              }
            : subscription,
        ),
        error: null,
      };
    case REACTIVATE_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        pending: false,
        data: state.data,
        error: action.payload.error,
      };

    case RESCHEDULE_ORDER_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case RESCHEDULE_ORDER_SUCCESS:
      return {
        ...state,
        pending: false,
        data: state.data.map((subscription) =>
          subscription.id === action.payload.subscription.id
            ? {
                ...subscription,
                ...action.payload.subscription,
              }
            : subscription,
        ),
        error: null,
      };
    case RESCHEDULE_ORDER_FAILURE:
      return {
        ...state,
        pending: false,
        data: state.data,
        error: action.payload.error,
      };
    case PLACE_ORDER_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case PLACE_ORDER_SUCCESS:
      return {
        ...state,
        pending: false,
        data: state.data.map((subscription) =>
          subscription.id === action.payload.subscription.id
            ? {
                ...subscription,
                ...action.payload.subscription,
              }
            : subscription,
        ),
        error: null,
      };
    case PLACE_ORDER_FAILURE:
      return {
        ...state,
        pending: false,
        data: [],
        error: action.payload.error,
      };

    case SKIP_NEXT_ORDER_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case SKIP_NEXT_ORDER_SUCCESS:
      return {
        ...state,
        pending: false,
        data: state.data.map((subscription) =>
          subscription.id === action.payload.subscription.id
            ? {
                ...subscription,
                ...action.payload.subscription,
              }
            : subscription,
        ),
        error: null,
      };
    case SKIP_NEXT_ORDER_FAILURE:
      return {
        ...state,
        pending: false,
        data: [],
        error: action.payload.error,
      };

    case CHANGE_PAYMENT_METHOD_REQUEST:
      return {
        ...state,
        pending: true,
      };

    case CHANGE_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        pending: false,
        data: state.data.map((subscription) => {
          return subscription.id === action.payload.subscription.subscription.id
            ? {
                ...subscription,
                paymentMethodId: action.payload.subscription.id,
              }
            : subscription;
        }),
        error: null,
      };

    case CHANGE_PAYMENT_METHOD_FAILURE:
      return {
        ...state,
        pending: false,
        data: [],
        error: action.payload.error,
      };

    case ADD_DISCOUNT_REQUEST:
      return {
        ...state,
        pending: true,
      };

    case ADD_DISCOUNT_SUCCESS:
      return {
        ...state,
        pending: false,
        data: state.data.map((subscription) =>
          subscription.id === action.payload.subscription.subscription.id
            ? {
                ...subscription,
                code: action.payload.subscription.code,
              }
            : subscription,
        ),
        error: null,
      };
    case ADD_DISCOUNT_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };

    case REMOVE_DISCOUNT_REQUEST:
      return {
        ...state,
        pending: true,
      };

    case REMOVE_DISCOUNT_SUCCESS:
      return {
        ...state,
        pending: false,
        data: state.data.map((subscription) =>
          subscription.id === action.payload.subscription.subscription.id
            ? {
                ...subscription,
                code: action.payload.subscription.code,
              }
            : subscription,
        ),
        error: null,
      };
    case REMOVE_DISCOUNT_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.payload.error,
      };

    case SWAP_LINE_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case SWAP_LINE_SUCCESS:
      return {
        ...state,
        pending: false,
        data: state.data.map((subscription) =>
          subscription.id === action.payload.id
            ? {
                ...subscription,
                lines: subscription.lines.map((lineItem) =>
                  lineItem.id === action.payload.lineItem.id
                    ? {
                        ...lineItem,
                        ...action.payload.lineItem,
                      }
                    : lineItem,
                ),
                billingPolicy: {
                  ...subscription.billingPolicy,
                  ...action.payload.billingPolicy,
                },
              }
            : subscription,
        ),
        error: null,
      };
    case SWAP_LINE_FAILURE:
      return {
        ...state,
        pending: false,
        data: state.data,
        error: action.payload.error,
      };
    case FETCH_FLOW_REWARD_BANNER_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_FLOW_REWARD_BANNER_SUCCESS:
      return {
        ...state,
        pending: false,
        data: state.data.map((subscription) =>
          subscription.id === action.payload.id
            ? {
                ...subscription,
                flowBanner: action.payload.data,
              }
            : subscription,
        ),
        error: null,
      };
    case FETCH_FLOW_REWARD_BANNER_FAILURE:
      return {
        ...state,
        pending: false,
        data: state.data,
        error: action.payload.error,
      };

    case FETCH_BENEFIT_CONTENT_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_BENEFIT_CONTENT_SUCCESS:
      return {
        ...state,
        pending: false,
        data: state.data.map((subscription) =>
          subscription.id === action.payload.subscription.data.id
            ? {
                ...subscription,
                ...action.payload.subscription.data,
              }
            : subscription,
        ),
        error: null,
      };
    case FETCH_BENEFIT_CONTENT_FAILURE:
      return {
        ...state,
        pending: false,
        data: state.data,
        error: action.payload.error,
      };

    case APPLY_BENEFIT_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case APPLY_BENEFIT_SUCCESS:
      return {
        ...state,
        pending: false,
        data: state.data.map((subscription) =>
          subscription.id === action.payload.subscription.id
            ? {
                ...subscription,
                ...action.payload.data,
              }
            : subscription,
        ),
        error: null,
      };
    case APPLY_BENEFIT_FAILURE:
      return {
        ...state,
        pending: false,
        data: state.data,
        error: action.payload.error,
      };

    case FETCH_CANCELLATION_OFFER_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_CANCELLATION_OFFER_SUCCESS:
      return {
        ...state,
        pending: false,
        data: state.data.map((subscription) =>
          subscription.id === action.payload.id
            ? {
                ...subscription,
                cancellationOffer: action.payload.data,
                cancellationFlowId: action.payload.cancellationFlowId,
              }
            : subscription,
        ),
        error: null,
      };

    case FETCH_CANCELLATION_OFFER_FAILURE:
      return {
        ...state,
        pending: false,
        data: state.data,
        error: action.payload.error,
      };

    case APPLY_CANCELLATION_OFFER_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case APPLY_CANCELLATION_OFFER_SUCCESS:
      return {
        ...state,
        pending: false,
        data: state.data.map((subscription) =>
          subscription.id === action.payload.id
            ? {
                ...subscription,
                ...action.payload.data,
              }
            : subscription,
        ),
        error: null,
      };
    case APPLY_CANCELLATION_OFFER_FAILURE:
      return {
        ...state,
        pending: false,
        data: state.data,
        error: action.payload.error,
      };

    case 'persist/REHYDRATE':
      return {
        ...state,
        data:
          action.payload && action.payload.subscriptions && action.payload.subscriptions.data
            ? action.payload.subscriptions.data
            : [],
      };
    default:
      return {
        ...state,
      };
  }
};
