import { useLayoutEffect, useState } from 'react';

const getCSSVariable = (variable: string) =>
  parseInt(getComputedStyle(document.documentElement).getPropertyValue(variable), 10);

export const useViewport = () => {
  const [width, setWidth] = useState(0);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useLayoutEffect(() => {
    setWidth(window.innerWidth);

    window.addEventListener('resize', handleWindowSizeChange);

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const breakpoints = {
    xs: getCSSVariable('xs'),
    sm: getCSSVariable('sm'),
    md: getCSSVariable('md'),
    lg: getCSSVariable('lg'),
    xl: getCSSVariable('xl'),
    xxl: getCSSVariable('xxl'),
  };

  const isXs = width <= breakpoints.sm;
  const isSm = width >= breakpoints.sm && width < breakpoints.md;
  const isMd = width >= breakpoints.md && width < breakpoints.lg;
  const isLg = width >= breakpoints.lg && width < breakpoints.xl;
  const isXl = width >= breakpoints.xl && width < breakpoints.xxl;
  const isXxl = width >= breakpoints.xxl;

  return {
    isXs,
    isSm,
    isMd,
    isLg,
    isXl,
    isXxl,
  };
};
