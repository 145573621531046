import { useDispatch, useSelector } from 'react-redux';
import { getDefaultSubscriptionSelector } from '@modules/subscriptions/core/selectors';
import { resumeSubscriptionRequest } from '@modules/subscriptions/core/actions';
import { Headline } from '@components/index';
import { SubscriptionReactivationDate } from '../SubscriptionReactivationDate';
import { SubscriptionReactivationInfo } from '../SubscriptionReactivationInfo';
import { SUBSCRIPTION_RESUME } from '@constants/subscription';

export const SubscriptionResume = () => {
  const dispatch = useDispatch();
  const defaultSubscription = useSelector(getDefaultSubscriptionSelector);

  const handleResumeSubscription = () => {
    dispatch(
      resumeSubscriptionRequest({
        id: defaultSubscription?.id,
      }),
    );
  };

  return (
    <div className="subscription-reactivation">
      <Headline>
        <p>ABO WIEDER AKTIVIEREN </p>
        <span className="subscription-reactivation-status subscription-reactivation-status-paused ">
          Pausiert
        </span>
      </Headline>

      <div className="md:flex gap-[1.2rem]">
        <SubscriptionReactivationInfo
          {...SUBSCRIPTION_RESUME}
          onReactivateClick={handleResumeSubscription}
        />

        <div className="hidden md:block subscription-reactivation-date">
          <SubscriptionReactivationDate />
        </div>
      </div>
    </div>
  );
};
