import { useSelector } from 'react-redux';
import { getPendingSelector, useCustomerForm } from '@modules/customer';
import { Button, Form, Input } from '@components/index';

type CustomerFormProps = {
  customer: ICustomer;
  handleCancel: VoidFunction;
};

export const CustomerForm = ({ customer, handleCancel }: CustomerFormProps) => {
  const {
    form,

    onSubmit,

    firstNameController,
    lastNameController,
    emailController,
  } = useCustomerForm(customer);

  const loading = useSelector(getPendingSelector);

  return (
    <>
      <Form form={form} handleSubmit={onSubmit}>
        <div className="lg:flex gap-[3.2rem] lg:mb-[2.8rem]">
          <div className="input-wrapper">
            <Input
              label="Email"
              placeholder=""
              tabIndex={1}
              disabled={true}
              {...emailController.field}
              validationOptions={{
                required: 'Expiration is required',
              }}
            />
          </div>
        </div>

        <div className="lg:flex gap-[3.2rem] lg:mb-[2.8rem]">
          <div className="input-wrapper">
            <Input
              label="Vorname"
              placeholder=""
              tabIndex={1}
              {...firstNameController.field}
              validationOptions={{
                required: 'Vorname ist erforderlich',
              }}
            />
          </div>
          <div className="input-wrapper">
            <Input
              label="Nachname"
              placeholder=""
              tabIndex={1}
              {...lastNameController.field}
              validationOptions={{
                required: 'Nachname ist erforderlich',
              }}
            />
          </div>
        </div>

        <div className="flex gap-x-[1.5rem] mt-[3rem]">
          <Button variant="primary" type="submit" loading={loading}>
            Speichern
          </Button>
          {handleCancel && (
            <Button variant="outline" onClick={handleCancel}>
              Abbrechen
            </Button>
          )}
        </div>
      </Form>
    </>
  );
};
