import clsx from 'clsx';
import { Link } from 'react-router-dom';

type DropdownItemProps = {
  title: string;
  to?: string;
  onClick?: () => void;
  isActive?: boolean;
};

const DropdownItem = ({ title, to = '', onClick, isActive = false }: DropdownItemProps) => {
  return (
    <>
      {to && (
        <Link className={clsx('menu-panel-link', { 'menu-panel-link-active': isActive })} to={to}>
          <span className="menu-title">{title}</span>
        </Link>
      )}
      {onClick && (
        <button
          className={clsx('menu-panel-btn', { 'menu-panel-btn-active': isActive })}
          onClick={onClick}
          disabled={isActive}
        >
          {title}
        </button>
      )}
    </>
  );
};

export { DropdownItem };
