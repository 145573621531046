export enum PetActivity {
  calm = 'calm',
  normal = 'normal',
  active = 'active',
}

export enum PetBody {
  skinny = 'skinny',
  ideal = 'ideal',
  chunky = 'chunky',
}
