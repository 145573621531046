export const FETCH_ORDER_SCHEDULE_REQUEST = 'FETCH_ORDER_SCHEDULE_REQUEST';
export const FETCH_ORDER_SCHEDULE_SUCCESS = 'FETCH_ORDER_SCHEDULE_SUCCESS';
export const FETCH_ORDER_SCHEDULE_FAILURE = 'FETCH_ORDER_SCHEDULE_FAILURE';

export const SKIP_ORDER_REQUEST = 'SKIP_ORDER_REQUEST';
export const SKIP_ORDER_SUCCESS = 'SKIP_ORDER_SUCCESS';
export const SKIP_ORDER_FAILURE = 'SKIP_ORDER_FAILURE';

export const UNSKIP_ORDER_REQUEST = 'UNSKIP_ORDER_REQUEST';
export const UNSKIP_ORDER_SUCCESS = 'UNSKIP_ORDER_SUCCESS';
export const UNSKIP_ORDER_FAILURE = 'UNSKIP_ORDER_FAILURE';
