import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addDiscountRequest, removeDiscountRequest } from '@modules/subscriptions/core/actions';
import {
  getDefaultSubscriptionSelector,
  getDiscountSelector,
  getErrorSelector,
} from '@modules/subscriptions/core/selectors';
import { KTSVG, toAbsoluteUrl } from '@helpers/index';
import { Modal } from '@components/index';

export const Discount = () => {
  const dispatch = useDispatch();
  const defaultSubscription = useSelector(getDefaultSubscriptionSelector);
  const discount = useSelector(getDiscountSelector);
  const error = useSelector(getErrorSelector);

  const [discountCode, setDiscountCode] = useState<string>(discount?.title ?? '');

  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleClose = () => setIsModalVisible(false);
  const handleOpenModal = () => setIsModalVisible(true);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const code = event.target.value;
    setDiscountCode(code);
  };

  const handleAddDiscount = (code: string) => {
    dispatch(
      addDiscountRequest({
        id: defaultSubscription?.id,
        params: {
          code,
        },
      }),
    );
  };

  const handleRemoveDiscount = (discountShopifyId: string) => {
    dispatch(
      removeDiscountRequest({
        id: defaultSubscription?.id,
        params: {
          discountShopifyId,
        },
      }),
    );
    setDiscountCode('');
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleAddDiscount(discountCode);
    }
  };

  return (
    <div className="discount-wrapper">
      {!discount ? (
        <div>
          <div className="relative flex">
            <label className="discount-wrapper-label -top-[1rem] bg-light-100">Rabattcode</label>
            <input
              type="text"
              placeholder="Rabattcode eingeben"
              value={discountCode}
              onChange={handleInputChange}
              onKeyDown={handleKeyPress}
              className="w-full discount-wrapper-input bg-light-100"
            />

            <button
              className="discount-handler-btn"
              onClick={() => handleAddDiscount(discountCode)}
            >
              Anwenden
            </button>
          </div>
          {error && <div className="discount-error-msg">Gib einen gültigen Rabattcode ein!</div>}
        </div>
      ) : (
        <div className="font-medium">
          <div className="flex items-center gap-[.5rem] mb-[.9rem]">
            <p>Rabatt</p>
            <span className="discount-wrapper-text">
              <p>{discount?.title}</p>
              <button onClick={() => handleRemoveDiscount(discount.shopifyId)}>
                <KTSVG path={toAbsoluteUrl('/media/svg/general/remove.svg')} />
              </button>
            </span>
          </div>

          <button onClick={handleOpenModal} className="flex items-center gap-[.5rem] text-primary">
            <KTSVG path={toAbsoluteUrl('/media/svg/general/edit.svg')} />
            <span className="underline">Rabattcode ändern</span>
          </button>

          {isModalVisible && (
            <Modal isOpen={true} handleClose={handleClose}>
              <div className="discount-wrapper-modal">
                <label className="top-[6.8rem] bg-white discount-wrapper-label">Rabattcode</label>
                <input
                  type="text"
                  placeholder="Enter the Rabattcode"
                  value={discountCode}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyPress}
                  className="w-full discount-wrapper-input"
                />
                <button
                  className="discount-handler-btn"
                  onClick={() => handleRemoveDiscount(discount.shopifyId)}
                >
                  Anwenden
                </button>
              </div>
            </Modal>
          )}
        </div>
      )}
    </div>
  );
};
