import { Modal } from '@components/index';
import { getCustomerSelector } from '@modules/customer/core/selectors';
import { useState } from 'react';
import { useSelector } from 'react-redux';

export const CustomerTag = () => {
  const customer = useSelector(getCustomerSelector);

  const [isModalVisible, setIsModalVisible] = useState(true);

  const handleClose = () => setIsModalVisible(false);
  if (!customer?.tags?.includes('Recharge')) return null;

  return (
    <div className="row gy-5 g-xl-8">
      <div className="mb-20">
        <Modal isOpen={isModalVisible} handleClose={handleClose}>
          <div className="flex justify-center text-3xl">
            <div className="xl:p-[10rem] py-[5rem] px-[2rem] text-[2.4rem] leading-[1.4]">
              <p className="mb-4">
                <span className="font-bold text-center">
                  Wir haben ein neues Kundenportal gestaltet
                </span>
                , um dir künftig ein noch intuitiveres und ansprechenderes Erlebnis bieten zu
                können.
              </p>
              <p className="mb-4">
                Nun haben wir noch ein Bitte an dich:
                <span className="ml-2 font-bold text-center">
                  Damit wir dein Futterabo im neuen Kundenportal aktivieren können und künftige
                  Zahlungen darüber abgewickelt werden können, ist es nötig, dass du deine
                  Zahlungsmethode bei HelloBello erneut eingibst bzw. bestätigst!
                </span>
              </p>
              <p className="mb-[4]">
                Hierzu klicke im neuen Portal oben links auf den Menüpunkt “Profil”, klicke dort im
                Abschnitt “Zahlungsarten” auf “Bearbeiten” und dann dort auf “Sende mir Email”. Du
                erhältst dann von uns eine Email mit einem Link, über den du deine Zahlungsmethode
                aktualisieren kannst. Abodetails und - preis bleiben unverändert. Die Aktivierung
                deines Abos im neuen Portal erfolgt danach spätestens am nächsten Werktag.
              </p>
              <br />
              <p>
                (<b>Hinweis:</b> Falls du als <b>Zahlungsmethode PayPal</b> bei der Aktualisierung
                auswählst, wirst du nach Weiterleitung zu deinem PayPal Konto aufgefordert, eine
                <b> Zahlung von 0,01€</b> zu bestätigen. Dies ist notwendig, damit PayPal dein Konto
                für künftige Zahlungen an HelloBello autorisieren kann).
              </p>
              <br />
              <p> Solltest du Fragen haben, melde dich gerne bei uns!</p>
              <br />
              Liebe Grüße <br /> Nadine & Christina
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};
