import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { getDefaultSubscriptionSelector } from '@modules/subscriptions/core/selectors';
import { updateSettings } from '../../settings/core/actions';
import { ALL_RECIPES } from '@constants/subscription';

interface IUseSubscriptionHook {
  defaultSubscription: ISubscription;
  setDefaultSubscription: (subscriptions: ISubscription[]) => void;
  switchDefaultSubscription: (subscription: ISubscription) => void;
}

export const useSubscriptions = (): IUseSubscriptionHook => {
  const dispatch = useDispatch();

  const defaultSubscription = useSelector(getDefaultSubscriptionSelector);

  const setDefaultSubscription = (subscriptions: ISubscription[]) => {
    if (!defaultSubscription) {
      const newDefaultSubscription = subscriptions.length ? subscriptions[0] : null;
      dispatch(updateSettings({ defaultSubscription: newDefaultSubscription?.id ?? null }));
      updateSubscriptionOptions(newDefaultSubscription);
    }
  };

  const switchDefaultSubscription = (subscription: ISubscription) => {
    dispatch(updateSettings({ defaultSubscription: subscription?.id }));
    updateSubscriptionOptions(subscription);
  };

  const updateSubscriptionOptions = (subscription: ISubscription | null) => {
    const subscriptionOptions = subscription?.lines?.[0].product?.options;
    const subscriptionRecipes = ALL_RECIPES.map((recipe) => recipe.id).filter((recipe) =>
      subscriptionOptions.recipes.includes(recipe as string),
    );

    dispatch(
      updateSettings({
        subscriptionOptions: {
          recipes: subscriptionRecipes,
          cycle: subscriptionOptions?.cycle,
          pension: subscriptionOptions?.pension,
        },
      }),
    );
  };

  return {
    defaultSubscription,
    setDefaultSubscription,
    switchDefaultSubscription,
  };
};
