import {
  FETCH_SUBSCRIPTIONS_REQUEST,
  FETCH_SUBSCRIPTIONS_SUCCESS,
  FETCH_SUBSCRIPTIONS_FAILURE,
  CANCEL_SUBSCRIPTION_REQUEST,
  CANCEL_SUBSCRIPTION_SUCCESS,
  CANCEL_SUBSCRIPTION_FAILURE,
  FETCH_CANCELLATION_FLOW_REQUEST,
  FETCH_CANCELLATION_FLOW_SUCCESS,
  FETCH_CANCELLATION_FLOW_FAILURE,
  PAUSE_SUBSCRIPTION_REQUEST,
  PAUSE_SUBSCRIPTION_SUCCESS,
  PAUSE_SUBSCRIPTION_FAILURE,
  RESUME_SUBSCRIPTION_REQUEST,
  RESUME_SUBSCRIPTION_SUCCESS,
  RESUME_SUBSCRIPTION_FAILURE,
  REACTIVATE_SUBSCRIPTION_REQUEST,
  REACTIVATE_SUBSCRIPTION_SUCCESS,
  REACTIVATE_SUBSCRIPTION_FAILURE,
  UPDATE_SUBSCRIPTION_ADDRESS_REQUEST,
  UPDATE_SUBSCRIPTION_ADDRESS_SUCCESS,
  UPDATE_SUBSCRIPTION_ADDRESS_FAILURE,
  UPDATE_SUBSCRIPTION_NEXT_CHARGE_DATE_REQUEST,
  UPDATE_SUBSCRIPTION_NEXT_CHARGE_DATE_SUCCESS,
  UPDATE_SUBSCRIPTION_NEXT_CHARGE_DATE_FAILURE,
  RESCHEDULE_ORDER_REQUEST,
  RESCHEDULE_ORDER_SUCCESS,
  RESCHEDULE_ORDER_FAILURE,
  PLACE_ORDER_REQUEST,
  PLACE_ORDER_SUCCESS,
  PLACE_ORDER_FAILURE,
  SKIP_NEXT_ORDER_REQUEST,
  SKIP_NEXT_ORDER_SUCCESS,
  SKIP_NEXT_ORDER_FAILURE,
  CHANGE_PAYMENT_METHOD_REQUEST,
  CHANGE_PAYMENT_METHOD_SUCCESS,
  CHANGE_PAYMENT_METHOD_FAILURE,
  ADD_DISCOUNT_REQUEST,
  ADD_DISCOUNT_SUCCESS,
  ADD_DISCOUNT_FAILURE,
  REMOVE_DISCOUNT_REQUEST,
  REMOVE_DISCOUNT_SUCCESS,
  REMOVE_DISCOUNT_FAILURE,
  SWAP_LINE_REQUEST,
  SWAP_LINE_SUCCESS,
  SWAP_LINE_FAILURE,
  FETCH_FLOW_REWARD_BANNER_REQUEST,
  FETCH_FLOW_REWARD_BANNER_SUCCESS,
  FETCH_FLOW_REWARD_BANNER_FAILURE,
  FETCH_BENEFIT_CONTENT_REQUEST,
  FETCH_BENEFIT_CONTENT_SUCCESS,
  FETCH_BENEFIT_CONTENT_FAILURE,
  APPLY_BENEFIT_REQUEST,
  APPLY_BENEFIT_SUCCESS,
  APPLY_BENEFIT_FAILURE,
  FETCH_CANCELLATION_OFFER_REQUEST,
  FETCH_CANCELLATION_OFFER_SUCCESS,
  FETCH_CANCELLATION_OFFER_FAILURE,
  APPLY_CANCELLATION_OFFER_REQUEST,
  APPLY_CANCELLATION_OFFER_SUCCESS,
  APPLY_CANCELLATION_OFFER_FAILURE,
} from './actionTypes';
import {
  FetchSubscriptionsRequest,
  FetchSubscriptionsSuccess,
  FetchSubscriptionsSuccessPayload,
  FetchSubscriptionsFailure,
  FetchSubscriptionsFailurePayload,
  UpdateSubscriptionAddressRequestPayload,
  UpdateSubscriptionAddressRequest,
  UpdateSubscriptionAddressSuccessPayload,
  UpdateSubscriptionAddressSuccess,
  UpdateSubscriptionAddressFailurePayload,
  UpdateSubscriptionAddressFailure,
  CancelSubscriptionRequest,
  CancelSubscriptionRequestPayload,
  CancelSubscriptionSuccess,
  CancelSubscriptionSuccessPayload,
  CancelSubscriptionFailure,
  CancelSubscriptionFailurePayload,
  FetchCancellationFlowRequest,
  FetchCancellationFlowRequestPayload,
  FetchCancellationFlowSuccess,
  FetchCancellationFlowSuccessPayload,
  FetchCancellationFlowFailure,
  FetchCancellationFlowFailurePayload,
  ResumeSubscriptionRequest,
  ResumeSubscriptionRequestPayload,
  ResumeSubscriptionSuccess,
  ResumeSubscriptionSuccessPayload,
  ResumeSubscriptionFailure,
  ResumeSubscriptionFailurePayload,
  PauseSubscriptionRequest,
  PauseSubscriptionRequestPayload,
  PauseSubscriptionSuccess,
  PauseSubscriptionSuccessPayload,
  PauseSubscriptionFailure,
  PauseSubscriptionFailurePayload,
  ReactivateSubscriptionRequest,
  ReactivateSubscriptionRequestPayload,
  ReactivateSubscriptionSuccess,
  ReactivateSubscriptionSuccessPayload,
  ReactivateSubscriptionFailure,
  ReactivateSubscriptionFailurePayload,
  UpdateSubscriptionNextChargeDateSuccessPayload,
  UpdateSubscriptionNextChargeDateSuccess,
  UpdateSubscriptionNextChargeDateFailurePayload,
  UpdateSubscriptionNextChargeDateFailure,
  UpdateSubscriptionNextChargeDateRequestPayload,
  UpdateSubscriptionNextChargeDateRequest,
  RescheduleOrderRequest,
  RescheduleOrderRequestPayload,
  RescheduleOrderSuccess,
  RescheduleOrderSuccessPayload,
  RescheduleOrderFailure,
  RescheduleOrderFailurePayload,
  PlaceOrderRequest,
  PlaceOrderRequestPayload,
  PlaceOrderSuccess,
  PlaceOrderSuccessPayload,
  PlaceOrderFailure,
  PlaceOrderFailurePayload,
  SkipNextOrderRequest,
  SkipNextOrderRequestPayload,
  SkipNextOrderSuccess,
  SkipNextOrderSuccessPayload,
  SkipNextOrderFailure,
  SkipNextOrderFailurePayload,
  ChangePaymentMethodRequest,
  ChangePaymentMethodRequestPayload,
  ChangePaymentMethodSuccess,
  ChangePaymentMethodSuccessPayload,
  ChangePaymentMethodFailure,
  ChangePaymentMethodFailurePayload,
  AddDiscountRequest,
  AddDiscountRequestPayload,
  AddDiscountSuccess,
  AddDiscountSuccessPayload,
  AddDiscountFailure,
  AddDiscountFailurePayload,
  RemoveDiscountRequest,
  RemoveDiscountRequestPayload,
  RemoveDiscountSuccess,
  RemoveDiscountSuccessPayload,
  RemoveDiscountFailure,
  RemoveDiscountFailurePayload,
  SwapLineRequest,
  SwapLineRequestPayload,
  SwapLineSuccess,
  SwapLineSuccessPayload,
  SwapLineFailure,
  SwapLineFailurePayload,
  FetchFlowRewardBannerRequest,
  FetchFlowRewardBannerRequestPayload,
  FetchFlowRewardBannerSuccess,
  FetchFlowRewardBannerSuccessPayload,
  FetchFlowRewardBannerFailure,
  FetchFlowRewardBannerFailurePayload,
  FetchBenefitContentRequest,
  FetchBenefitContentRequestPayload,
  FetchBenefitContentSuccess,
  FetchBenefitContentSuccessPayload,
  FetchBenefitContentFailure,
  FetchBenefitContentFailurePayload,
  ApplyBenefitRequest,
  ApplyBenefitRequestPayload,
  ApplyBenefitSuccess,
  ApplyBenefitSuccessPayload,
  ApplyBenefitFailure,
  ApplyBenefitFailurePayload,
  FetchCancellationOfferRequest,
  FetchCancellationOfferRequestPayload,
  FetchCancellationOfferSuccess,
  FetchCancellationOfferSuccessPayload,
  FetchCancellationOfferFailure,
  FetchCancellationOfferFailurePayload,
  ApplyCancellationOfferRequest,
  ApplyCancellationOfferRequestPayload,
  ApplyCancellationOfferSuccess,
  ApplyCancellationOfferSuccessPayload,
  ApplyCancellationOfferFailure,
  ApplyCancellationOfferFailurePayload,
} from './types';

export const fetchSubscriptionsRequest = (): FetchSubscriptionsRequest => ({
  type: FETCH_SUBSCRIPTIONS_REQUEST,
});

export const fetchSubscriptionsSuccess = (
  payload: FetchSubscriptionsSuccessPayload,
): FetchSubscriptionsSuccess => ({
  type: FETCH_SUBSCRIPTIONS_SUCCESS,
  payload,
});

export const fetchSubscriptionsFailure = (
  payload: FetchSubscriptionsFailurePayload,
): FetchSubscriptionsFailure => ({
  type: FETCH_SUBSCRIPTIONS_FAILURE,
  payload,
});

export const updateSubscriptionAddressRequest = (
  payload: UpdateSubscriptionAddressRequestPayload,
): UpdateSubscriptionAddressRequest => ({
  type: UPDATE_SUBSCRIPTION_ADDRESS_REQUEST,
  payload,
});

export const updateSubscriptionAddressSuccess = (
  payload: UpdateSubscriptionAddressSuccessPayload,
): UpdateSubscriptionAddressSuccess => ({
  type: UPDATE_SUBSCRIPTION_ADDRESS_SUCCESS,
  payload,
});

export const updateSubscriptionAddressFailure = (
  payload: UpdateSubscriptionAddressFailurePayload,
): UpdateSubscriptionAddressFailure => ({
  type: UPDATE_SUBSCRIPTION_ADDRESS_FAILURE,
  payload,
});

export const cancelSubscriptionRequest = (
  payload: CancelSubscriptionRequestPayload,
): CancelSubscriptionRequest => ({
  type: CANCEL_SUBSCRIPTION_REQUEST,
  payload,
});

export const cancelSubscriptionSuccess = (
  payload: CancelSubscriptionSuccessPayload,
): CancelSubscriptionSuccess => ({
  type: CANCEL_SUBSCRIPTION_SUCCESS,
  payload,
});

export const cancelSubscriptionFailure = (
  payload: CancelSubscriptionFailurePayload,
): CancelSubscriptionFailure => ({
  type: CANCEL_SUBSCRIPTION_FAILURE,
  payload,
});

export const fetchCancellationFlowRequest = (
  payload: FetchCancellationFlowRequestPayload,
): FetchCancellationFlowRequest => ({
  type: FETCH_CANCELLATION_FLOW_REQUEST,
  payload,
});

export const fetchCancellationFlowSuccess = (
  payload: FetchCancellationFlowSuccessPayload,
): FetchCancellationFlowSuccess => ({
  type: FETCH_CANCELLATION_FLOW_SUCCESS,
  payload,
});

export const fetchCancellationFlowFailure = (
  payload: FetchCancellationFlowFailurePayload,
): FetchCancellationFlowFailure => ({
  type: FETCH_CANCELLATION_FLOW_FAILURE,
  payload,
});

export const pauseSubscriptionRequest = (
  payload: PauseSubscriptionRequestPayload,
): PauseSubscriptionRequest => ({
  type: PAUSE_SUBSCRIPTION_REQUEST,
  payload,
});

export const pauseSubscriptionSuccess = (
  payload: PauseSubscriptionSuccessPayload,
): PauseSubscriptionSuccess => ({
  type: PAUSE_SUBSCRIPTION_SUCCESS,
  payload,
});

export const pauseSubscriptionFailure = (
  payload: PauseSubscriptionFailurePayload,
): PauseSubscriptionFailure => ({
  type: PAUSE_SUBSCRIPTION_FAILURE,
  payload,
});

export const resumeSubscriptionRequest = (
  payload: ResumeSubscriptionRequestPayload,
): ResumeSubscriptionRequest => ({
  type: RESUME_SUBSCRIPTION_REQUEST,
  payload,
});

export const resumeSubscriptionSuccess = (
  payload: ResumeSubscriptionSuccessPayload,
): ResumeSubscriptionSuccess => ({
  type: RESUME_SUBSCRIPTION_SUCCESS,
  payload,
});

export const resumeSubscriptionFailure = (
  payload: ResumeSubscriptionFailurePayload,
): ResumeSubscriptionFailure => ({
  type: RESUME_SUBSCRIPTION_FAILURE,
  payload,
});

export const reactivateSubscriptionRequest = (
  payload: ReactivateSubscriptionRequestPayload,
): ReactivateSubscriptionRequest => ({
  type: REACTIVATE_SUBSCRIPTION_REQUEST,
  payload,
});

export const reactivateSubscriptionSuccess = (
  payload: ReactivateSubscriptionSuccessPayload,
): ReactivateSubscriptionSuccess => ({
  type: REACTIVATE_SUBSCRIPTION_SUCCESS,
  payload,
});

export const reactivateSubscriptionFailure = (
  payload: ReactivateSubscriptionFailurePayload,
): ReactivateSubscriptionFailure => ({
  type: REACTIVATE_SUBSCRIPTION_FAILURE,
  payload,
});

export const updateSubscriptionNextChargeDateRequest = (
  payload: UpdateSubscriptionNextChargeDateRequestPayload,
): UpdateSubscriptionNextChargeDateRequest => ({
  type: UPDATE_SUBSCRIPTION_NEXT_CHARGE_DATE_REQUEST,
  payload,
});

export const updateSubscriptionNextChargeDateSuccess = (
  payload: UpdateSubscriptionNextChargeDateSuccessPayload,
): UpdateSubscriptionNextChargeDateSuccess => ({
  type: UPDATE_SUBSCRIPTION_NEXT_CHARGE_DATE_SUCCESS,
  payload,
});

export const updateSubscriptionNextChargeDateFailure = (
  payload: UpdateSubscriptionNextChargeDateFailurePayload,
): UpdateSubscriptionNextChargeDateFailure => ({
  type: UPDATE_SUBSCRIPTION_NEXT_CHARGE_DATE_FAILURE,
  payload,
});

export const rescheduleOrderRequest = (
  payload: RescheduleOrderRequestPayload,
): RescheduleOrderRequest => ({
  type: RESCHEDULE_ORDER_REQUEST,
  payload,
});

export const rescheduleOrderSuccess = (
  payload: RescheduleOrderSuccessPayload,
): RescheduleOrderSuccess => ({
  type: RESCHEDULE_ORDER_SUCCESS,
  payload,
});

export const rescheduleOrderFailure = (
  payload: RescheduleOrderFailurePayload,
): RescheduleOrderFailure => ({
  type: RESCHEDULE_ORDER_FAILURE,
  payload,
});

export const placeOrderRequest = (payload: PlaceOrderRequestPayload): PlaceOrderRequest => ({
  type: PLACE_ORDER_REQUEST,
  payload,
});

export const placeOrderSuccess = (payload: PlaceOrderSuccessPayload): PlaceOrderSuccess => ({
  type: PLACE_ORDER_SUCCESS,
  payload,
});

export const placeOrderFailure = (payload: PlaceOrderFailurePayload): PlaceOrderFailure => ({
  type: PLACE_ORDER_FAILURE,
  payload,
});

export const skipNextOrderRequest = (
  payload: SkipNextOrderRequestPayload,
): SkipNextOrderRequest => ({
  type: SKIP_NEXT_ORDER_REQUEST,
  payload,
});

export const skipNextOrderSuccess = (
  payload: SkipNextOrderSuccessPayload,
): SkipNextOrderSuccess => ({
  type: SKIP_NEXT_ORDER_SUCCESS,
  payload,
});

export const skipNextOrderFailure = (
  payload: SkipNextOrderFailurePayload,
): SkipNextOrderFailure => ({
  type: SKIP_NEXT_ORDER_FAILURE,
  payload,
});

// CHANGE PAYMENT METHOD

export const changePaymentMethodRequest = (
  payload: ChangePaymentMethodRequestPayload,
): ChangePaymentMethodRequest => ({
  type: CHANGE_PAYMENT_METHOD_REQUEST,
  payload,
});

export const changePaymentMethodSuccess = (
  payload: ChangePaymentMethodSuccessPayload,
): ChangePaymentMethodSuccess => ({
  type: CHANGE_PAYMENT_METHOD_SUCCESS,
  payload,
});

export const changePaymentMethodFailure = (
  payload: ChangePaymentMethodFailurePayload,
): ChangePaymentMethodFailure => ({
  type: CHANGE_PAYMENT_METHOD_FAILURE,
  payload,
});

export const addDiscountRequest = (payload: AddDiscountRequestPayload): AddDiscountRequest => ({
  type: ADD_DISCOUNT_REQUEST,
  payload,
});

export const addDiscountSuccess = (payload: AddDiscountSuccessPayload): AddDiscountSuccess => ({
  type: ADD_DISCOUNT_SUCCESS,
  payload,
});

export const addDiscountFailure = (payload: AddDiscountFailurePayload): AddDiscountFailure => ({
  type: ADD_DISCOUNT_FAILURE,
  payload,
});

export const removeDiscountRequest = (
  payload: RemoveDiscountRequestPayload,
): RemoveDiscountRequest => ({
  type: REMOVE_DISCOUNT_REQUEST,
  payload,
});

export const removeDiscountSuccess = (
  payload: RemoveDiscountSuccessPayload,
): RemoveDiscountSuccess => ({
  type: REMOVE_DISCOUNT_SUCCESS,
  payload,
});

export const removeDiscountFailure = (
  payload: RemoveDiscountFailurePayload,
): RemoveDiscountFailure => ({
  type: REMOVE_DISCOUNT_FAILURE,
  payload,
});

export const swapLineRequest = (payload: SwapLineRequestPayload): SwapLineRequest => ({
  type: SWAP_LINE_REQUEST,
  payload,
});

export const swapLineSuccess = (payload: SwapLineSuccessPayload): SwapLineSuccess => ({
  type: SWAP_LINE_SUCCESS,
  payload,
});

export const swapLineFailure = (payload: SwapLineFailurePayload): SwapLineFailure => ({
  type: SWAP_LINE_FAILURE,
  payload,
});

export const fetchFlowRewardBannerRequest = (
  payload: FetchFlowRewardBannerRequestPayload,
): FetchFlowRewardBannerRequest => ({
  type: FETCH_FLOW_REWARD_BANNER_REQUEST,
  payload,
});

export const fetchFlowRewardBannerSuccess = (
  payload: FetchFlowRewardBannerSuccessPayload,
): FetchFlowRewardBannerSuccess => ({
  type: FETCH_FLOW_REWARD_BANNER_SUCCESS,
  payload,
});

export const fetchFlowRewardBannerFailure = (
  payload: FetchFlowRewardBannerFailurePayload,
): FetchFlowRewardBannerFailure => ({
  type: FETCH_FLOW_REWARD_BANNER_FAILURE,
  payload,
});

export const fetchBenefitContentRequest = (
  payload: FetchBenefitContentRequestPayload,
): FetchBenefitContentRequest => ({
  type: FETCH_BENEFIT_CONTENT_REQUEST,
  payload,
});

export const fetchBenefitContentSuccess = (
  payload: FetchBenefitContentSuccessPayload,
): FetchBenefitContentSuccess => ({
  type: FETCH_BENEFIT_CONTENT_SUCCESS,
  payload,
});

export const fetchBenefitContentFailure = (
  payload: FetchBenefitContentFailurePayload,
): FetchBenefitContentFailure => ({
  type: FETCH_BENEFIT_CONTENT_FAILURE,
  payload,
});

export const applyBenefitRequest = (payload: ApplyBenefitRequestPayload): ApplyBenefitRequest => ({
  type: APPLY_BENEFIT_REQUEST,
  payload,
});

export const applyBenefitSuccess = (payload: ApplyBenefitSuccessPayload): ApplyBenefitSuccess => ({
  type: APPLY_BENEFIT_SUCCESS,
  payload,
});

export const applyBenefitFailure = (payload: ApplyBenefitFailurePayload): ApplyBenefitFailure => ({
  type: APPLY_BENEFIT_FAILURE,
  payload,
});

export const fetchCancellationOfferRequest = (
  payload: FetchCancellationOfferRequestPayload,
): FetchCancellationOfferRequest => ({
  type: FETCH_CANCELLATION_OFFER_REQUEST,
  payload,
});

export const fetchCancellationOfferSuccess = (
  payload: FetchCancellationOfferSuccessPayload,
): FetchCancellationOfferSuccess => ({
  type: FETCH_CANCELLATION_OFFER_SUCCESS,
  payload,
});

export const fetchCancellationOfferFailure = (
  payload: FetchCancellationOfferFailurePayload,
): FetchCancellationOfferFailure => ({
  type: FETCH_CANCELLATION_OFFER_FAILURE,
  payload,
});

export const applyCancellationOfferRequest = (
  payload: ApplyCancellationOfferRequestPayload,
): ApplyCancellationOfferRequest => ({
  type: APPLY_CANCELLATION_OFFER_REQUEST,
  payload,
});

export const applyCancellationOfferSuccess = (
  payload: ApplyCancellationOfferSuccessPayload,
): ApplyCancellationOfferSuccess => ({
  type: APPLY_CANCELLATION_OFFER_SUCCESS,
  payload,
});

export const applyCancellationOfferFailure = (
  payload: ApplyCancellationOfferFailurePayload,
): ApplyCancellationOfferFailure => ({
  type: APPLY_CANCELLATION_OFFER_FAILURE,
  payload,
});
