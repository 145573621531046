import axios, { AxiosResponse } from 'axios';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as API from '@api/types';
import * as AuthActions from './actions';
import { AuthAction } from './actionTypes';

/**
 * Sends a login link to the specified email address. The user can use this link to log in without a password.
 *
 * @param {string} email The email address to which the login link should be sent.
 * @returns {Promise<API.Response<API.AuthSendLoginLinkResponse>>} A promise that resolves to an empty success response upon successful delivery of the login link.
 */
const authSendLoginLink = async (
  email: string,
): Promise<API.Response<API.AuthSendLoginLinkResponse>> => {
  const params: API.AuthSendLoginLinkRequest = {
    email,
  };
  return await axios.post<API.AuthSendLoginLinkResponse>(`/api/v1/auth/link`, params);
};

/**
 * Authenticates a user using a session token, typically after the user has clicked a login link.
 *
 * @param {string} sessionToken The session token of the user obtained from the login link.
 * @returns {Promise<API.Response<API.AuthInitResponse>>} A promise that resolves to an object containing the user's access token if authentication is successful.
 */
const authInit = async (sessionToken: string): Promise<API.Response<API.AuthInitResponse>> => {
  const params: API.AuthInitRequest = {
    sessionToken,
  };

  return await axios.post(`/api/v1/auth`, params);
};

/**
 * Refreshes the user's access token using a valid refresh token. This is used to keep the user logged in without requiring reauthentication.
 *
 * @param {string} accessToken The current access token of the user that needs to be refreshed.
 * @returns {Promise<API.Response<API.AuthRefreshResponse>>} A promise that resolves to an object containing the new access token.
 */
const authRefresh = async (accessToken: string): Promise<API.Response<API.AuthRefreshResponse>> => {
  const params: API.AuthRefreshRequest = {
    accessToken,
  };

  return await axios.post(`/api/v1/auth/refresh`, params);
};

/**
 * Logs out the user by invalidating their access token. This ends the user's session.
 *
 * @param {string} accessToken The access token of the user to be logged out.
 * @returns {Promise<API.Response<API.AuthLogoutResponse>>} A promise that resolves to an empty success response upon successful logout.
 */
const authLogout = async (accessToken: string): Promise<API.Response<API.AuthLogoutResponse>> => {
  const params: API.AuthLogoutRequest = {
    accessToken,
  };

  return await axios.post(`/api/v1/logout`, params);
};

function* authRequestLoginLinkSaga(action: ReturnType<typeof AuthActions.authRequestLoginLink>) {
  try {
    const response: AxiosResponse<API.AuthSendLoginLinkResponse> = yield call(() =>
      authSendLoginLink(action.payload.email),
    );

    yield put(AuthActions.authRequestLoginLinkSuccess(response.data));
  } catch (e: any) {
    yield put(
      AuthActions.authRequestLoginLinkFailure({
        error: e.message,
      }),
    );
  }
}

function* initAuthSaga(action: ReturnType<typeof AuthActions.authInit>) {
  try {
    const response: AxiosResponse<API.AuthInitResponse> = yield call(() =>
      authInit(action.payload.sessionToken),
    );

    yield put(AuthActions.authInitSuccess(response.data));
  } catch (e: any) {
    yield put(
      AuthActions.authInitFailure({
        error: e.message,
      }),
    );
  }
}

function* refreshAuthSaga(action: ReturnType<typeof AuthActions.authRefresh>) {
  try {
    const response: AxiosResponse<API.AuthRefreshResponse> = yield call(() =>
      authRefresh(action.payload.accessToken),
    );

    yield put(AuthActions.authRefreshSuccess(response.data));
  } catch (e: any) {
    yield put(
      AuthActions.authRefreshFailure({
        error: e.message,
      }),
    );
  }
}

function* logoutSaga(action: ReturnType<typeof AuthActions.authLogout>) {
  try {
    const response: AxiosResponse<API.AuthLogoutResponse> = yield call(() =>
      authLogout(action.payload.accessToken),
    );

    yield put(AuthActions.authLogoutSuccess(response.data));
  } catch (e: any) {
    yield put(
      AuthActions.authLogoutFailure({
        error: e.message,
      }),
    );
  }
}

export function* authSaga() {
  yield all([
    takeLatest(AuthAction.REQUEST_LOGIN_LINK, authRequestLoginLinkSaga),
    takeLatest(AuthAction.INIT, initAuthSaga),
    takeLatest(AuthAction.REFRESH, refreshAuthSaga),
    takeLatest(AuthAction.LOGOUT, logoutSaga),
  ]);
}
