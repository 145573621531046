import {
  FETCH_ORDER_SCHEDULE_REQUEST,
  FETCH_ORDER_SCHEDULE_SUCCESS,
  FETCH_ORDER_SCHEDULE_FAILURE,
  SKIP_ORDER_REQUEST,
  SKIP_ORDER_SUCCESS,
  SKIP_ORDER_FAILURE,
  UNSKIP_ORDER_REQUEST,
  UNSKIP_ORDER_SUCCESS,
  UNSKIP_ORDER_FAILURE,
} from './actionTypes';

import { OrderScheduleActions, OrderScheduleState } from './types';

const initialState: OrderScheduleState = {
  pending: false,
  data: [],
  error: null,
};

export const orderScheduleReducer = (state = initialState, action: OrderScheduleActions) => {
  switch (action.type) {
    case FETCH_ORDER_SCHEDULE_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_ORDER_SCHEDULE_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.payload.orderSchedule,
        error: null,
      };
    case FETCH_ORDER_SCHEDULE_FAILURE:
      return {
        ...state,
        pending: false,
        data: [],
        error: action.payload.error,
      };

    case SKIP_ORDER_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case SKIP_ORDER_SUCCESS:
      return {
        ...state,
        pending: false,
        data: state.data.map((order) =>
          order.id === action.payload.order.id
            ? {
                ...order,
                ...action.payload.order,
              }
            : order,
        ),
        error: null,
      };
    case SKIP_ORDER_FAILURE:
      return {
        ...state,
        pending: false,
        data: state.data,
        error: action.payload.error,
      };
    case UNSKIP_ORDER_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case UNSKIP_ORDER_SUCCESS:
      return {
        ...state,
        pending: false,
        data: state.data.map((order) =>
          order.id === action.payload.order.id
            ? {
                ...order,
                ...action.payload.order,
              }
            : order,
        ),
        error: null,
      };
    case UNSKIP_ORDER_FAILURE:
      return {
        ...state,
        pending: false,
        data: state.data,
        error: action.payload.error,
      };

    case 'persist/REHYDRATE':
      return {
        ...state,
        data:
          action.payload && action.payload.orderSchedule && action.payload.orderSchedule.data
            ? action.payload.orderSchedule.data
            : [],
      };
    default:
      return {
        ...state,
      };
  }
};
