export const FETCH_SUBSCRIPTIONS_REQUEST = 'FETCH_SUBSCRIPTIONS_REQUEST';
export const FETCH_SUBSCRIPTIONS_SUCCESS = 'FETCH_SUBSCRIPTIONS_SUCCESS';
export const FETCH_SUBSCRIPTIONS_FAILURE = 'FETCH_SUBSCRIPTIONS_REQUEST';

export const UPDATE_SUBSCRIPTION_ADDRESS_REQUEST = 'UPDATE_SUBSCRIPTION_ADDRESS_REQUEST';
export const UPDATE_SUBSCRIPTION_ADDRESS_SUCCESS = 'UPDATE_SUBSCRIPTION_ADDRESS_SUCCESS';
export const UPDATE_SUBSCRIPTION_ADDRESS_FAILURE = 'UPDATE_SUBSCRIPTION_ADDRESS_FAILURE';

export const CANCEL_SUBSCRIPTION_REQUEST = 'CANCEL_SUBSCRIPTION_REQUEST';
export const CANCEL_SUBSCRIPTION_SUCCESS = 'CANCEL_SUBSCRIPTION_SUCCESS';
export const CANCEL_SUBSCRIPTION_FAILURE = 'CANCEL_SUBSCRIPTION_FAILURE';

export const FETCH_CANCELLATION_FLOW_REQUEST = 'FETCH_CANCELLATION_FLOW_REQUEST';
export const FETCH_CANCELLATION_FLOW_SUCCESS = 'FETCH_CANCELLATION_FLOW_SUCCESS';
export const FETCH_CANCELLATION_FLOW_FAILURE = 'FETCH_CANCELLATION_FLOW_FAILURE';

export const PAUSE_SUBSCRIPTION_REQUEST = 'PAUSE_SUBSCRIPTION_REQUEST';
export const PAUSE_SUBSCRIPTION_SUCCESS = 'PAUSE_SUBSCRIPTION_SUCCESS';
export const PAUSE_SUBSCRIPTION_FAILURE = 'PAUSE_SUBSCRIPTION_FAILURE';

export const RESUME_SUBSCRIPTION_REQUEST = 'RESUME_SUBSCRIPTION_REQUEST';
export const RESUME_SUBSCRIPTION_SUCCESS = 'RESUME_SUBSCRIPTION_SUCCESS';
export const RESUME_SUBSCRIPTION_FAILURE = 'RESUME_SUBSCRIPTION_FAILURE';

export const REACTIVATE_SUBSCRIPTION_REQUEST = 'REACTIVATE_SUBSCRIPTION_REQUEST';
export const REACTIVATE_SUBSCRIPTION_SUCCESS = 'REACTIVATE_SUBSCRIPTION_SUCCESS';
export const REACTIVATE_SUBSCRIPTION_FAILURE = 'REACTIVATE_SUBSCRIPTION_FAILURE';

export const UPDATE_SUBSCRIPTION_NEXT_CHARGE_DATE_REQUEST =
  'UPDATE_SUBSCRIPTION_NEXT_CHARGE_DATE_REQUEST';
export const UPDATE_SUBSCRIPTION_NEXT_CHARGE_DATE_SUCCESS =
  'UPDATE_SUBSCRIPTION_NEXT_CHARGE_DATE_SUCCESS';
export const UPDATE_SUBSCRIPTION_NEXT_CHARGE_DATE_FAILURE =
  'UPDATE_SUBSCRIPTION_NEXT_CHARGE_DATE_FAILURE';

export const RESCHEDULE_ORDER_REQUEST = 'RESCHEDULE_ORDER_REQUEST';
export const RESCHEDULE_ORDER_SUCCESS = 'RESCHEDULE_ORDER_SUCCESS';
export const RESCHEDULE_ORDER_FAILURE = 'RESCHEDULE_ORDER_FAILURE';

export const PLACE_ORDER_REQUEST = 'PLACE_ORDER_REQUEST';
export const PLACE_ORDER_SUCCESS = 'PLACE_ORDER_SUCCESS';
export const PLACE_ORDER_FAILURE = 'PLACE_ORDER_FAILURE';

export const SKIP_NEXT_ORDER_REQUEST = 'SKIP_NEXT_ORDER_REQUEST';
export const SKIP_NEXT_ORDER_SUCCESS = 'SKIP_NEXT_ORDER_SUCCESS';
export const SKIP_NEXT_ORDER_FAILURE = 'SKIP_NEXT_ORDER_FAILURE';

export const CHANGE_PAYMENT_METHOD_REQUEST = 'CHANGE_PAYMENT_METHOD_REQUEST';
export const CHANGE_PAYMENT_METHOD_SUCCESS = 'CHANGE_PAYMENT_METHOD_SUCCESS';
export const CHANGE_PAYMENT_METHOD_FAILURE = 'CHANGE_PAYMENT_METHOD_FAILURE';

export const ADD_DISCOUNT_REQUEST = 'ADD_DISCOUNT_REQUEST';
export const ADD_DISCOUNT_SUCCESS = 'ADD_DISCOUNT_SUCCESS';
export const ADD_DISCOUNT_FAILURE = 'ADD_DISCOUNT_FAILURE';

export const REMOVE_DISCOUNT_REQUEST = 'REMOVE_DISCOUNT_REQUEST';
export const REMOVE_DISCOUNT_SUCCESS = 'REMOVE_DISCOUNT_SUCCESS';
export const REMOVE_DISCOUNT_FAILURE = 'REMOVE_DISCOUNT_FAILURE';

export const SWAP_LINE_REQUEST = 'SWAP_LINE_REQUEST';
export const SWAP_LINE_SUCCESS = 'SWAP_LINE_SUCCESS';
export const SWAP_LINE_FAILURE = 'SWAP_LINE_FAILURE';

export const FETCH_FLOW_REWARD_BANNER_REQUEST = 'FETCH_FLOW_REWARD_BANNER_REQUEST';
export const FETCH_FLOW_REWARD_BANNER_SUCCESS = 'FETCH_FLOW_REWARD_BANNER_SUCCESS';
export const FETCH_FLOW_REWARD_BANNER_FAILURE = 'FETCH_FLOW_REWARD_BANNER_FAILURE';

export const FETCH_BENEFIT_CONTENT_REQUEST = 'FETCH_BENEFIT_CONTENT_REQUEST';
export const FETCH_BENEFIT_CONTENT_SUCCESS = 'FETCH_BENEFIT_CONTENT_SUCCESS';
export const FETCH_BENEFIT_CONTENT_FAILURE = 'FETCH_BENEFIT_CONTENT_FAILURE';

export const APPLY_BENEFIT_REQUEST = 'APPLY_BENEFIT_REQUEST';
export const APPLY_BENEFIT_SUCCESS = 'APPLY_BENEFIT_SUCCESS';
export const APPLY_BENEFIT_FAILURE = 'APPLY_BENEFIT_FAILURE';

export const FETCH_CANCELLATION_OFFER_REQUEST = 'FETCH_CANCELLATION_OFFER_REQUEST';
export const FETCH_CANCELLATION_OFFER_SUCCESS = 'FETCH_CANCELLATION_OFFER_SUCCESS';
export const FETCH_CANCELLATION_OFFER_FAILURE = 'FETCH_CANCELLATION_OFFER_FAILURE';

export const APPLY_CANCELLATION_OFFER_REQUEST = 'APPLY_CANCELLATION_OFFER_REQUEST';
export const APPLY_CANCELLATION_OFFER_SUCCESS = 'APPLY_CANCELLATION_OFFER_SUCCESS';
export const APPLY_CANCELLATION_OFFER_FAILURE = 'APPLY_CANCELLATION_OFFER_FAILURE';
