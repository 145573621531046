import { createSelector } from 'reselect';
import { RootState } from 'src/store/store';

const getPending = (state: RootState) => state.auth?.pending;

const getAuth = (state: RootState) => state.auth?.data;

const getError = (state: RootState) => state.auth?.error;

export const getLogin = createSelector(getAuth, (auth) => auth);

export const getIsLoggedIn = createSelector(getAuth, (auth) => Boolean(auth?.accessToken));

export const getAuthTokenSelector = createSelector(getAuth, (auth) => auth?.accessToken);

export const getPendingSelector = createSelector(getPending, (pending) => pending);

export const getErrorSelector = createSelector(getError, (error) => error);
