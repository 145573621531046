import {
  FETCH_PRODUCT_REQUEST,
  FETCH_PRODUCT_SUCCESS,
  FETCH_PRODUCT_FAILURE,
  FETCH_VARIANT_REQUEST,
  FETCH_VARIANT_SUCCESS,
  FETCH_VARIANT_FAILURE,
} from './actionTypes';
import {
  FetchProductRequest,
  FetchProductSuccess,
  FetchProductSuccessPayload,
  FetchProductFailure,
  FetchProductFailurePayload,
  FetchVariantRequest,
  FetchVariantRequestPayload,
  FetchVariantSuccess,
  FetchVariantSuccessPayload,
  FetchVariantFailure,
  FetchVariantFailurePayload,
  FetchProductRequestPayload,
} from './types';

export const fetchProductRequest = (payload: FetchProductRequestPayload): FetchProductRequest => ({
  type: FETCH_PRODUCT_REQUEST,
  payload,
});

export const fetchProductSuccess = (payload: FetchProductSuccessPayload): FetchProductSuccess => ({
  type: FETCH_PRODUCT_SUCCESS,
  payload,
});

export const fetchProductFailure = (payload: FetchProductFailurePayload): FetchProductFailure => ({
  type: FETCH_PRODUCT_FAILURE,
  payload,
});

export const fetchVariantRequest = (payload: FetchVariantRequestPayload): FetchVariantRequest => ({
  type: FETCH_VARIANT_REQUEST,
  payload,
});

export const fetchVariantSuccess = (payload: FetchVariantSuccessPayload): FetchVariantSuccess => ({
  type: FETCH_VARIANT_SUCCESS,
  payload,
});

export const fetchVariantFailure = (payload: FetchVariantFailurePayload): FetchVariantFailure => ({
  type: FETCH_VARIANT_FAILURE,
  payload,
});
