import axios, { AxiosResponse } from 'axios';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import {
  fetchOrderHistoryFailure,
  fetchOrderHistoryRequest,
  fetchOrderHistorySuccess,
} from './actions';
import { FETCH_ORDER_HISTORY_REQUEST } from './actionTypes';

const getOrderHistory = async (subscriptionId: number) =>
  await axios.get<any>(`/api/v1/subscriptions/${subscriptionId}/order_history`);

function* fetchOrderHistorySaga(action: ReturnType<typeof fetchOrderHistoryRequest>) {
  try {
    const response: AxiosResponse<any> = yield call(() =>
      getOrderHistory(action.payload.subscriptionId),
    );

    yield put(
      fetchOrderHistorySuccess({
        orders: response.data,
      }),
    );
  } catch (e: any) {
    yield put(
      fetchOrderHistoryFailure({
        error: e.message,
      }),
    );
  }
}

export function* ordersSaga() {
  yield all([takeLatest(FETCH_ORDER_HISTORY_REQUEST, fetchOrderHistorySaga)]);
}
