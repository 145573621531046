import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { DrawerComponent } from '../assets/ts/components';
import { useLayout } from './core';

export function MasterInit() {
  const dispatch = useDispatch();
  const { config } = useLayout();
  const isFirstRun = useRef(true);

  const pluginsInitialization = () => {
    isFirstRun.current = false;
    setTimeout(() => {
      DrawerComponent.bootstrap();
    }, 500);
  };

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;

      pluginsInitialization();
    }
  }, [dispatch, config]);

  useEffect(() => {
    // Update this with each major update or some bug fixes, when we need to clear customer's cache
    const currentVersion = '0.5.1';
    const storedVersion = localStorage.getItem('appVersion');

    if (storedVersion !== currentVersion) {
      localStorage.clear();
      localStorage.setItem('appVersion', currentVersion);
    }
  }, []);

  return <></>;
}
