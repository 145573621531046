import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Input } from '@components/index';
import { useAuthForm } from '../hooks/useAuthForm';
import { getErrorSelector, getPendingSelector } from '@modules/auth';
import { authResetLoginLinkRequest } from '../core/actions';
import { KTSVG, toAbsoluteUrl } from '@helpers/index';

export const LoginForm = () => {
  const dispatch = useDispatch();

  const {
    form,

    onSubmit,

    emailController,

    isLoginLinkRequested,
    setIsLoginLinkRequested,
  } = useAuthForm();

  const isPendingAuth = useSelector(getPendingSelector);

  const loginError = useSelector(getErrorSelector);

  useEffect(() => {
    return () => {
      if (isLoginLinkRequested) dispatch(authResetLoginLinkRequest());
    };
  }, [dispatch, isLoginLinkRequested]);

  useEffect(() => {
    return () => {
      setIsLoginLinkRequested(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Form form={form} handleSubmit={onSubmit} className="login-form-wrapper">
      <div className="flex flex-col">
        <div className="input-wrapper">
          <Input
            label="Email"
            placeholder="Email"
            tabIndex={1}
            {...emailController.field}
            validationOptions={{
              required: 'E-Mail ist erforderlich',
            }}
          />
        </div>

        {(isLoginLinkRequested || loginError) && !isPendingAuth ? (
          <div className="text-center" role="alert">
            {loginError ? (
              <p className="login-msg login-msg-error">
                Login fehlgeschlagen, bitte E-mail erneut eingeben
              </p>
            ) : (
              <div className="login-msg-wrapper">
                <div>
                  <KTSVG path={toAbsoluteUrl('/media/svg/general/checkmark.svg')} />
                </div>
                <p className="login-msg login-msg-success">E-Mail erfolgreich gesendet!</p>
              </div>
            )}
          </div>
        ) : null}

        <Button variant="primary" type="submit" loading={isPendingAuth}>
          Sende mir Anmelde-Link
        </Button>
      </div>
    </Form>
  );
};
