import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import clsx from 'clsx';
import SVG from 'react-inlinesvg';
import { NAVI, MenuItem } from '@constants/navi';
import { KTSVG, toAbsoluteUrl } from '@helpers/index';
import { useLayout } from '@layout/core';
import {
  getDefaultSubscriptionSelector,
  getPetSelector,
} from '@modules/subscriptions/core/selectors';
import { SubscriptionsListDropdown } from '@modules/dashboard/components/subscription-list/SubscriptionListDropdown';
import { Divider } from '../Divider';

export function HeaderWrapper() {
  const location = useLocation();
  const intl = useIntl();

  const { config, classes, attributes } = useLayout();
  const { aside } = config;

  const pet = useSelector(getPetSelector);
  const defaultSubscription = useSelector(getDefaultSubscriptionSelector);

  const activeMenuItem = NAVI.find((menuItem: MenuItem) => menuItem.to === location.pathname);
  const activeMenuTitleValues = activeMenuItem?.pet ? { petName: pet?.name } : null;

  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <div
        id="kt_header"
        className={clsx('header flex lg:hidden', classes.header.join(' '), 'items-stretch', {
          scrolled,
        })}
        {...attributes.headerMenu}
      >
        <div className="container flex items-center justify-between mx-auto">
          <div className="flex items-center justify-between flex-grow w-full h-full">
            <Link to="/dashboard">
              <SVG src={toAbsoluteUrl('/media/logos/logo.svg')} className="w-[8rem] mx-[1.5rem]" />
            </Link>

            {aside.display && (
              <div className="flex items-center" title="Show aside menu">
                <div
                  className="btn btn-icon btn-active-light-primary btn-custom hamburger-menu-btn"
                  id="kt_aside_mobile_toggle"
                >
                  <KTSVG path="/media/svg/header/hamburger-menu.svg" className="svg-icon-2x" />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {activeMenuItem && (
        <div id="kt_subheader" className="flex-grow w-full mx-auto subheader md:container">
          <div className="subheader-wrapper lg:grow p-[2rem] pb-[2.4rem]">
            <div className="items-stretch flex-shrink-0 subheader-title ">
              {intl.formatMessage({ id: activeMenuItem?.title }, { ...activeMenuTitleValues })}
            </div>
            <div>
              <SubscriptionsListDropdown />
            </div>
          </div>

          {defaultSubscription && defaultSubscription?.status === 'ACTIVE' && (
            <span className="flex justify-center px-[1.5rem] md:hidden">
              <Divider />
            </span>
          )}
        </div>
      )}
    </>
  );
}
