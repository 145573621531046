import { toAbsoluteUrl } from '@helpers/AssetHelpers';
import { KTSVG } from '@helpers/index';

export const ToastifySuccess = () => {
  return <KTSVG path={toAbsoluteUrl('/media/svg/general/checkmark.svg')} />;
};

export const ToastifyError = () => {
  return <KTSVG path={toAbsoluteUrl('/media/svg/aside/closeToastify.svg')} />;
};

export const ToastifyClose = () => {
  return <KTSVG path={toAbsoluteUrl('/media/svg/aside/close-notification.svg')} />;
};
