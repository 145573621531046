import { combineReducers } from 'redux';
import { authReducer } from '../app/modules/auth/core/reducer';
import { addressesReducer } from 'src/app/modules/addresses/core/reducer';
import { customerReducer } from '../app/modules/customer/core/reducer';
import { ordersReducer } from '../app/modules/orders/core/reducer';
import { orderScheduleReducer } from '../app/modules/order-schedule/core/reducer';
import { paymentMethodsReducer } from '../app/modules/payment/core/reducer';
import { productsReducer } from '../app/modules/products/core/reducers';
import { subscriptionsReducer } from '../app/modules/subscriptions/core/reducers';
import { settingsReducer } from '../app/modules/settings/core/reducers';
import { AuthAction } from '@modules/auth/core/actionTypes';

const appReducer = combineReducers({
  auth: authReducer,
  addresses: addressesReducer,
  customer: customerReducer,
  orders: ordersReducer,
  orderSchedule: orderScheduleReducer,
  paymentMethods: paymentMethodsReducer,
  products: productsReducer,
  subscriptions: subscriptionsReducer,
  settings: settingsReducer,
});

export type RootState = ReturnType<typeof appReducer>;

export const rootReducer = (state: any, action: any) => {
  if (action.type === AuthAction.LOGOUT_SUCCESS || action.type === AuthAction.REFRESH_FAILURE) {
    state = undefined;
  }

  return appReducer(state, action);
};
