export const calcDeliveryDate = (nextChargeDate: string): [Date, Date] => {
  const date = new Date(nextChargeDate);

  let daysToTuesday = (9 - date.getDay()) % 7;

  if (date.getDay() === 2) daysToTuesday = 7;

  if (date.getDay() === 1) daysToTuesday = 8;

  let nextTuesday = new Date(date);
  nextTuesday.setDate(date.getDate() + daysToTuesday);

  let nextThursday = new Date(date);
  nextThursday.setDate(date.getDate() + daysToTuesday + 2);

  return [nextTuesday, nextThursday];
};
