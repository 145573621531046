import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { KTSVG, toAbsoluteUrl } from '@helpers/index';
import { getFlowBannerTextSelector } from '@modules/subscriptions/core/selectors';
import { UpcomingRewardFlyout } from './UpcomingRewardFlyout';

export const UpcomingReward = () => {
  const flowBannerText = useSelector(getFlowBannerTextSelector);

  const decodeHtmlEntities = (str: string) => {
    const textArea = document.createElement('textarea');
    textArea.innerHTML = str;
    return textArea.value;
  };

  const decodedFlowBannerText = decodeHtmlEntities(flowBannerText);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const discountMatch =
    decodedFlowBannerText && typeof decodedFlowBannerText === 'string'
      ? decodedFlowBannerText.match(/(\d+)%/)
      : null;

  const discountPercentage = discountMatch ? discountMatch[1] : '';

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  return (
    <>
      <button
        className={clsx('reward-sticky md:hidden', { '!hidden': isModalOpen })}
        onClick={toggleModal}
      >
        {discountPercentage ? `${discountPercentage}% Rabatt` : '% Rabatt'}
      </button>
      <div
        className={clsx(
          'reward-banner hidden md:flex text-white rounded-[3rem] lg:rounded-tr-[0] lg:rounded-br-[0] xl:gap-16 justify-between xl:justify-normal items-center xl:items-start',
          {},
        )}
      >
        <div className="reward-banner-md-scr">
          <div className="flex gap-[2rem]">
            <h2 className="reward-banner-title">FAST GESCHAFFT!</h2>
          </div>
          <p className="reward-banner-message-almost">{decodedFlowBannerText}</p>
          <span className="reward-banner-fade">Geschenkkarte</span>
        </div>
        <div className="relative">
          <div className="w-[16rem] h-[13.2rem]">
            <KTSVG
              path={toAbsoluteUrl(`/media/svg/reward/reward-discount-${discountPercentage}.svg`)}
            />
          </div>
        </div>
      </div>
      <UpcomingRewardFlyout
        isModalOpen={isModalOpen}
        toggleModal={toggleModal}
        discountPercentage={discountPercentage}
        flowBannerText={decodedFlowBannerText}
      />
    </>
  );
};
