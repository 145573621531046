import { useSelector } from 'react-redux';
import clsx from 'clsx';
import {
  getDefaultSubscriptionSelector,
  getPetSelector,
} from '@modules/subscriptions/core/selectors';
import { KTSVG, toAbsoluteUrl } from '@helpers/index';
import { useSubscriptions } from '@modules/subscriptions/hooks/useSubscriptions';

type SubscriptionsListItemProps = {
  subscription: ISubscription;
  isSelected: boolean;
};

export const SubscriptionsListItem = ({ subscription, isSelected }: SubscriptionsListItemProps) => {
  const pet = useSelector(getPetSelector);

  const { switchDefaultSubscription } = useSubscriptions();

  const switchSubscription = (subscription: ISubscription) =>
    switchDefaultSubscription(subscription);

  const defaultSubscription = useSelector(getDefaultSubscriptionSelector);

  const todaysYear = new Date().getFullYear();

  const birthYear = subscription?.pet?.birth_year ?? todaysYear;
  const birthMonth = subscription?.pet?.birth_month ?? 1;
  const months = (todaysYear - birthYear) * 12 + new Date().getMonth() + 1 - birthMonth;
  const years = Math.floor(months / 12);

  return (
    <div className="subscription-switcher">
      <span
        className={clsx('subscription-switcher-status', {
          '!opacity-0 ': subscription?.status === 'ACTIVE',
          '!text-light-yellow': subscription?.status === 'PAUSED',
        })}
      >
        {subscription?.status === 'CANCELLED'
          ? 'Gekündigt'
          : subscription?.status === 'PAUSED'
          ? 'Pausiert'
          : '.'}
      </span>
      <button
        onClick={() => switchSubscription(subscription)}
        className={clsx('subscription-switcher-button', {
          'border border-primary !shadow-custom': subscription?.id === defaultSubscription?.id,
          '!border-light-400': subscription?.status === 'CANCELLED',
          '!border-light-yellow': subscription?.status === 'PAUSED',
        })}
      >
        <div className="subscription-switcher-item-wrapper">
          <div
            className={clsx(
              'subscription-switcher-item',
              {
                'opacity-50': subscription?.id !== defaultSubscription?.id,
              },
              { '!bg-light-400 !opacity-100': subscription?.status === 'CANCELLED' },

              { '!bg-light-yellow !opacity-100': subscription?.status === 'PAUSED' },
            )}
          >
            <div className="subscription-switcher-gender">
              {pet?.gender === 'm' ? (
                <KTSVG path={toAbsoluteUrl('/media/svg/pet/gender-m-white.svg')} />
              ) : (
                <KTSVG path={toAbsoluteUrl('/media/svg/pet/gender-f-white.svg')} />
              )}
            </div>
            <div className="subscription-switcher-name">{subscription?.pet?.name ?? '-'}</div>
          </div>
          <div
            className={clsx('text-primary subscription-pet-year', {
              '!text-light-400': subscription?.status === 'CANCELLED',
              '!text-light-yellow': subscription?.status === 'PAUSED',
            })}
          >
            <div className="subscription-pet-year-item">
              <KTSVG path={toAbsoluteUrl('/media/svg/pet/briefcase.svg')} />
              <p>{years} Jahre</p>
            </div>
            <div className="subscription-pet-year-item">
              <KTSVG path={toAbsoluteUrl('/media/svg/pet/paw.svg')} />
              <p className="subscription-pet-breed">
                {Array.isArray(subscription?.pet?.breed)
                  ? subscription?.pet?.breed[0]?.replace(/\s/g, '') || 'Mischling'
                  : (subscription?.pet?.breed || 'Mischling').replace(/\s/g, '')}
              </p>
            </div>
          </div>
        </div>
      </button>
    </div>
  );
};
