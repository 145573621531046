import { ALL_RECIPES, RECIPES } from '@constants/subscription';

export const getDisabledRecipes = (pet: IPet) => {
  const recipesIDs = ALL_RECIPES.map((recipe) => recipe.id);

  const isDisabledCG =
    pet?.allergies.includes('Rind') ||
    pet?.allergies.includes('Reis') ||
    pet?.health_issues.includes('Pankreatitis');

  const isDisabledGS =
    pet?.allergies.includes('Huhn') || pet?.allergies.includes('Hirse (ist glutenfrei)');

  const isDisabledRT =
    pet?.allergies.includes('Schwein') ||
    pet?.allergies.includes('Kartoffeln') ||
    pet?.allergies.includes('Süßkartoffeln');

  const isPuppy = pet?.dog_stage === 'puppy';

  return recipesIDs.filter((recipeId: RECIPES) => {
    switch (recipeId) {
      case RECIPES.CG:
        return isDisabledCG || isPuppy;
      case RECIPES.GS:
        return isDisabledGS || isPuppy;
      case RECIPES.RT:
        return isDisabledRT;
      default:
        return false;
    }
  });
};
