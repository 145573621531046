import { useDispatch, useSelector } from 'react-redux';
import { getDefaultSubscriptionSelector } from '@modules/subscriptions/core/selectors';
import { reactivateSubscriptionRequest } from '@modules/subscriptions/core/actions';
import { Headline } from '@components/index';
import { SubscriptionReactivationInfo } from '../SubscriptionReactivationInfo';
import { SubscriptionReactivationDate } from '../SubscriptionReactivationDate';
import { SUBSCRIPTION_REACTIVATE } from '@constants/subscription';

export const SubscriptionReactivate = () => {
  const dispatch = useDispatch();
  const defaultSubscription = useSelector(getDefaultSubscriptionSelector);

  const handleReactivateSubscription = () => {
    dispatch(
      reactivateSubscriptionRequest({
        id: defaultSubscription?.id,
      }),
    );
  };

  return (
    <div className="subscription-reactivation">
      <Headline>
        <p>ABO WIEDER AKTIVIEREN </p>
      </Headline>

      <div className="md:flex gap-[1.2rem]">
        <SubscriptionReactivationInfo
          {...SUBSCRIPTION_REACTIVATE}
          onReactivateClick={handleReactivateSubscription}
        />

        <div className="hidden md:block subscription-reactivation-date">
          <SubscriptionReactivationDate />
        </div>
      </div>
    </div>
  );
};
