import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { toAbsoluteUrl } from '@helpers/AssetHelpers';

interface SupplementItemProps {
  item: any;
  isActive: boolean;
  isPrev: boolean;
}

export const SupplementItem = ({ item, isActive, isPrev }: SupplementItemProps) => {
  const {
    title,
    text,
    img,
    backgroundColor,
    inactiveBgColor,
    boxShadow,
    link,
    ingredientPopupImg,
  } = item;

  const appliedBackgroundColor = isActive ? backgroundColor : inactiveBgColor;

  const [showIngredient, setShowIngredient] = useState(false);

  useEffect(() => {
    if (isActive) {
      const showTimer = setTimeout(() => {
        setShowIngredient(true);
      }, 500);

      return () => clearTimeout(showTimer);
    } else {
      setShowIngredient(false);
    }
  }, [isActive]);

  return (
    <a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      className={`relative flex supplement-item ${
        isActive
          ? 'active-supplement-slide'
          : isPrev
          ? 'prev-supplement-slide'
          : 'next-supplement-slide'
      }`}
      style={{ backgroundColor: appliedBackgroundColor, boxShadow }}
    >
      <div className="supplement-item-text">
        <span>{text}</span>
        <h3>{title}</h3>
      </div>
      <img
        src={toAbsoluteUrl(img)}
        alt="supplement-item"
        className={clsx(
          'supplement-item-img z-50 w-[5.4rem] h-[8.3rem] lg:w-[6.2rem] lg:h-[9.4rem] absolute bottom-0',
          {
            '!right-[2rem]': isPrev,
          },
        )}
        {...(!isPrev &&
          !isActive && {
            style: { right: 'calc(100% - 2rem)', transform: 'translateX(100%)' },
          })}
      />
      {isActive && (
        <img
          className={`ingredient-popup-image animate__slideUp ${
            showIngredient ? 'visible' : 'invisible'
          } w-[9.2rem] h-[10.7rem] z-0`}
          src={toAbsoluteUrl(ingredientPopupImg)}
          alt="reward-confetti"
        />
      )}
    </a>
  );
};
