import { ReactNode } from 'react';

type LoginWrapperProps = {
  children: ReactNode;
};

export const LoginWrapper = ({ children }: LoginWrapperProps) => {
  return (
    <div className="flex flex-col items-center justify-center h-full login">
      <div className="login-content">{children}</div>
    </div>
  );
};
