import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import clsx from 'clsx';
import { KTSVG, toAbsoluteUrl } from '@helpers/index';
import {
  getPetSelector,
  getSubscriptionsWithPetsSortedByLF,
} from '@modules/subscriptions/core/selectors';
import { SubscriptionsListItem } from './SubscriptionListItem';
import { PET_PROFILE_SLIDER } from '@constants/sliders';

export const SubscriptionList = () => {
  const subscriptions = useSelector(getSubscriptionsWithPetsSortedByLF);

  const pet = useSelector(getPetSelector);

  const [isTextVisible, setIsTextVisible] = useState(false);

  const toggleTextVisibility = () => {
    setIsTextVisible(!isTextVisible);
  };

  const sortingOrder: { [key in ISubscription['status']]: number } = {
    ACTIVE: 1,
    PAUSED: 2,
    CANCELLED: 3,
  };

  const subscriptionsSortedByActivity = [...subscriptions].sort(
    (a, b) => sortingOrder[a.status] - sortingOrder[b.status],
  );

  return (
    <div className="flex flex-col flex-grow h-full">
      <div
        className={clsx('flex flex-col h-full pet-subscription-wrapper', {
          'pb-[3rem]': subscriptionsSortedByActivity.length > 1,
        })}
      >
        <div
          className={clsx('p-[2rem] md:p-[3rem] relative', {
            'flex gap-[2rem]': subscriptionsSortedByActivity.length < 1,
          })}
        >
          <h4 className="pet-subscription-title">
            Meine <span className="font-bold">Hunde</span>
          </h4>

          <div
            className={clsx('flex items-start h-full', {
              'flex-col': subscriptionsSortedByActivity.length > 1,
              'flex-row gap-[1.5rem] xl:gap-[3.7rem] ': subscriptionsSortedByActivity.length <= 1,
            })}
          >
            {subscriptionsSortedByActivity.length === 1 ? (
              <SubscriptionsListItem
                subscription={subscriptionsSortedByActivity[0]}
                isSelected={true}
              />
            ) : (
              <div className="h-full pet-subscription-slick">
                <Swiper {...PET_PROFILE_SLIDER}>
                  {subscriptionsSortedByActivity.map(
                    (subscription: ISubscription, index: number) => (
                      <SwiperSlide key={subscription.id}>
                        <SubscriptionsListItem
                          subscription={subscription}
                          isSelected={pet?.name !== ''}
                        />
                      </SwiperSlide>
                    ),
                  )}
                </Swiper>
                <div className="pet-profile-button-next"></div>
                <div className="pet-profile-button-prev"></div>
              </div>
            )}
            <div
              className={clsx('pr-[1rem] leading-[2.2rem]', {
                'md:mt-[3.4rem]': subscriptionsSortedByActivity.length <= 1,
              })}
            >
              <p
                className={clsx('mb-[1.7rem] md:mb-[1.2rem] mt-[3.4rem] md:mt-0', {
                  'hidden lg:block mt-0': subscriptionsSortedByActivity.length > 1,
                })}
              >
                Hast du noch weitere vierbeinige Familienmitglieder?
              </p>

              <div className="relative flex items-center md:mt-0">
                {subscriptionsSortedByActivity.length > 1 && (
                  <>
                    <button
                      className="flex items-center gap-[.5rem] mr-[.5rem] lg:hidden w-[1.8rem] h-[1.8rem]"
                      onClick={toggleTextVisibility}
                    >
                      <KTSVG
                        path={toAbsoluteUrl('/media/svg/general/question-mark.svg')}
                        className="w-full h-full overflow-hidden rounded-full svg-icon-4hx question-mark"
                        svgClassName="w-full h-full"
                      />
                    </button>

                    {isTextVisible && (
                      <span className="welcome-message-popup">
                        Hast du noch weitere vierbeinige Familienmitglieder?
                        <span>
                          <KTSVG
                            path={toAbsoluteUrl('/media/svg/general/indicator.svg')}
                            className="svg-icon-4hx"
                          />
                        </span>
                      </span>
                    )}
                  </>
                )}
                <a
                  target="_blank"
                  className="bottom-[12rem] flex items-center gap-[1rem] md:gap-[1.7rem]"
                  href="https://futter.hellobello.at"
                  rel="noreferrer"
                >
                  <span className="text-[1.6rem] lg:text-[1.8rem] font-bold text-primary leading-[2rem]">
                    Neuen Hund hinzufügen
                  </span>
                  <KTSVG path={toAbsoluteUrl('/media/svg/general/plus.svg')} className="w-[2rem]" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
