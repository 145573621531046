import { useDispatch } from 'react-redux';
import { SubmitHandler, useController, useForm, UseFormReturn } from 'react-hook-form';
import { authRequestLoginLink, authResetLoginLinkRequest } from '../core/actions';
import { useState } from 'react';

export const useAuthForm = () => {
  const dispatch = useDispatch();

  const [isLoginLinkRequested, setIsLoginLinkRequested] = useState(false);

  const form: UseFormReturn<any> = useForm<any>({
    defaultValues: {
      email: '',
    },
  });

  const onSubmit: SubmitHandler<any> = async ({ email }: any) => {
    try {
      dispatch(authResetLoginLinkRequest());

      dispatch(
        authRequestLoginLink({
          email: email,
        }),
      );
    } catch (error) {
      console.log('Error occured while fetching login link for the customer', error);
    } finally {
      setIsLoginLinkRequested(true);
    }
  };

  const emailController = useController({
    name: 'email',
    control: form.control,
    defaultValue: '',
  });

  return {
    form,

    onSubmit,

    emailController,

    isLoginLinkRequested,
    setIsLoginLinkRequested,
  };
};
