export interface SubscriptionReactivationInfoDefaults {
  intro?: string;
  text: string;
  textExtra?: string;
  buttonText: string;
}

export const SKIP_SUBSCRIPTION = [
  'Noch genügend Futter?',
  'Kein Platz im Kühlschrank?',
  'Du bist verreist oder auf Urlaub?',
  'Überspringe deine nächste Bestellung einfach!',
];

export const PAUSE_SUBSCRIPTION = [
  'Du liebst HelloBello, möchtest jedoch eine Pause einlegen?',
  'Pausiere einfach und du hast bis zur Reaktivierung keine weiteren Kosten!',
  'Lege selbst fest, nach wieviel Wochen dein Abo wieder reaktiviert werden soll.',
];

export const CANCEL_SUBSCRIPTION = [
  `Du willst kein gesundes Frischfutter mehr für %{pet?.name}`,
  'Keine Freudensprünge mehr vorm Hundenapf?',
  `%{pet?.name} kann es sich nicht selbst aussuchen. Bist du sicher?`,
];

export enum RECIPES {
  CG = 'CG',
  GS = 'GS',
  RT = 'RT',
}

export const ALL_RECIPES = [
  {
    key: 'cg',
    id: RECIPES.CG,
    title: 'Cowgenuss',
    color: 'var(--hb-product-cg)',
    description: 'Mit Rind',
    img: '/media/svg/slider/beef.svg',
  },
  {
    key: 'gs',
    id: RECIPES.GS,
    title: 'Gockelspeis',
    color: 'var(--hb-product-gs)',
    description: 'Mit Huhn',
    img: '/media/svg/slider/chicken.svg',
  },
  {
    key: 'rt',
    id: RECIPES.RT,
    title: 'RINGELTANZ',
    color: 'var(--hb-product-rt)',
    description: 'Mit Schwein',
    img: '/media/svg/slider/pork.svg',
  },
];

export const SUBSCRIPTION_RESUME: SubscriptionReactivationInfoDefaults = {
  text: 'Dein Abo wird nach der gewählten Pausendauer automatisch fortgesetzt. Während der Pause entstehen dir keine Kosten. Du erhältst eine E-Mail, sobald dein Abo wieder aktiv ist.',
  textExtra: 'Du kannst es hier auch jederzeit selbst reaktivieren.',
  buttonText: 'Abo fortsetzen',
};

export const SUBSCRIPTION_REACTIVATE: SubscriptionReactivationInfoDefaults = {
  intro: 'Hat dein Vierbeiner seine letzten Mahlzeiten vermisst?',
  text: "Klicke einfach auf 'Abo reaktivieren', um dein Abo wieder zu starten. Betty freut sich bestimmt schon wieder auf sein gesundes Frischfutter!",
  buttonText: 'Abo reaktivieren',
};

export const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  className: 'mx-auto recipe-slider w-full',
  mobileFirst: true,
  responsive: [
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
