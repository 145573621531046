import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { checkIsActive, KTSVG } from '../../../../helpers';
import { useLayout } from '../../../core';

type AsideMenuItemProps = {
  to: string;
  title: string;
  icon?: string;
  sub?: boolean;
  type?: 'internal' | 'external';
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  cssClasses?: string[];
};

const AsideMenuItem = ({
  to,
  title,
  icon,
  sub,
  type = 'internal',
  onClick,
  cssClasses,
}: AsideMenuItemProps) => {
  const { pathname } = useLocation();
  const isActive = checkIsActive(pathname, to);
  const { config } = useLayout();
  const { aside } = config;

  return (
    <div className={clsx('menu-item', cssClasses?.join(' '), { 'menu-item-sub': sub })}>
      {type === 'internal' ? (
        <Link
          className={clsx('menu-link without-sub', { 'fill-black': !isActive, active: isActive })}
          to={to}
          onClick={onClick}
        >
          {icon && aside.menuIcon === 'svg' && (
            <span className="menu-icon">
              <KTSVG path={icon} className="svg-icon-2" />
            </span>
          )}
          <span className="menu-title">{title}</span>
        </Link>
      ) : type === 'external' ? (
        <a
          className={clsx('menu-link without-sub font-bold text-[1.4rem] md:text-[2rem]', {
            'fill-black': !isActive,
            active: isActive,
          })}
          href={to}
          target="_blank"
          rel="noopener noreferrer"
        >
          {icon && aside.menuIcon === 'svg' && (
            <span className="menu-icon">
              <KTSVG path={icon} className="svg-icon-2" />
            </span>
          )}
          <span className="menu-title">{title}</span>
        </a>
      ) : null}
    </div>
  );
};

export { AsideMenuItem };
