import { getCustomerSelector } from '@modules/customer';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

export const useDelightedSurvey = () => {
  const customer = useSelector(getCustomerSelector);

  useEffect(() => {
    if (!customer) return;

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    const today = new Date().toISOString();

    script.innerHTML = `
      !function(e,t,r,n){if(!e[n]){for(var a=e[n]=[],i=["survey","reset","config","init","set","get","event","identify","track","page","screen","group","alias",],s=0;s<i.length;s++){var c=i[s];a[c]=a[c]||function(e){return function(){var t=Array.prototype.slice.call(arguments);a.push([e,t])}}(c)}a.SNIPPET_VERSION="1.0.1";var o=t.createElement("script");o.type="text/javascript",o.async=!0,o.src="https://d2yyd1h5u9mauk.cloudfront.net/integrations/web/v1/library/"+r+"/"+n+".js";var l=t.getElementsByTagName("script")[0];l.parentNode.insertBefore(o,l)}}(window,document,"PMcVHZlYuhtb8BT7","delighted");

      delighted.survey({
        email: '${customer.email}',
        name: '${customer.firstName},${customer.lastName}', 
        createdAt: '${today}',
      });
    `;

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, [customer]);
};
