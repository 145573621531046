import { toAbsoluteUrl } from '@helpers/index';
import SVG from 'react-inlinesvg';
import { LoginWrapper } from './LoginWrapper';
import { useNavigate } from 'react-router-dom';
import { Button } from '@components/index';
import { useDispatch, useSelector } from 'react-redux';
import { authLogout } from '../core/actions';
import { getAuthTokenSelector } from '../core/selectors';
import { useQuery } from 'src/shared';

export const ReLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getQueryParam } = useQuery();

  const logoLink = window.location.host.includes('.de')
    ? 'https://hellobello.de'
    : 'https://hellobello.at';

  const sessionToken = getQueryParam('sessionToken');

  const accessToken = useSelector(getAuthTokenSelector);

  const relogin = () => {
    if (accessToken) dispatch(authLogout({ accessToken }));
    navigate(`/auth?sessionToken=${sessionToken}`);
  };

  const continueSession = () => navigate('/');

  return (
    <LoginWrapper>
      <div className="login-logo">
        <a href={logoLink} target="_blank" rel="noopener noreferrer">
          <SVG src={toAbsoluteUrl('/media/logos/logo.svg')} />
        </a>
      </div>
      <h1>
        <span className="login-headline">AUTHENTIFIZIERUNG AKTUALISIEREN</span>
        <span className="login-headline-sup">NEUE ANMELDUNG</span>
      </h1>

      <p className="login-text">
        Wir haben eine Änderung Ihres Authentifizierungstokens festgestellt. Dies könnte passieren,
        wenn Sie sich mit einem anderen Benutzerkonto angemeldet haben.
      </p>

      <a href="mailto:support@hellobello.at" className="text-center text-light-600">
        Hast du Schwierigkeiten beim Einloggen? Kontaktiere unser Support-Team!
      </a>

      <div className="flex flex-col gap-3 mt-12">
        <Button onClick={relogin} variant="outline">
          Erneut anmelden
        </Button>
        <Button onClick={continueSession}>Aktuelle Sitzung fortsetzen</Button>
      </div>
    </LoginWrapper>
  );
};
