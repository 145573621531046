import { KTSVG } from '../../../../../shared/helpers';
import { toAbsoluteUrl } from '../../../../../shared/helpers';
import { useSelector } from 'react-redux';
import { getCurrentOrderSelector } from '@modules/order-schedule/core/selectors';
import { Widget } from '@components/index';
import { calcDeliveryDate } from '@modules/subscriptions/utils/calcDeliveryDate';
import { FormattedDate } from 'react-intl';
import { Discount } from '@modules/subscriptions/components/discount/Discount';
import { getDiscountSelector } from '@modules/subscriptions/core/selectors';
import { SachetType } from 'src/app/types/types';
import { getVariantProductInfoSelector } from '@modules/products/core/selectors';

export const RecipeOverview = () => {
  const order = useSelector(getCurrentOrderSelector);
  const discount = useSelector(getDiscountSelector);

  const variantProductInfo = useSelector(getVariantProductInfoSelector);

  const [dateFrom, dateTo] = calcDeliveryDate(order?.billingDate);

  const lineItem = order?.lines?.[0];
  if (!order) return <p>Aktuell ist keine Bestellung offen.</p>;

  return (
    <Widget
      header={
        <p>
          <b>AKTUELLE</b> BESTELLUNG
        </p>
      }
      footer={
        order?.lines && (
          <div className="flex items-center justify-center gap-[5px]">
            {discount && (
              <span className="text-[1.6rem] text-light-600 line-through">
                €{lineItem?.price.toLocaleString('de-DE').replace('.', ',')}
              </span>
            )}
            <p className="text-[2.4rem] font-bold">
              €{lineItem?.discountedPrice.toLocaleString('de-DE').replace('.', ',')}
            </p>
          </div>
        )
      }
      footerBg="bg-primary-light"
    >
      <div className="bg-light-100 widget-recipe-wrapper">
        <div className="relative flex items-center justify-between font-medium text-primary mb-[1rem]">
          <span className="widget-recipe-kcal">{lineItem?.productTitle}</span>
          <div className="z-50 -mt-[5.5rem]">
            <img src="/media/img/misc/meal.png" alt="meal" width="150px" height="150px" />
          </div>
        </div>
        <div className="flex gap-[3rem] font-bold mb-[1.5rem] lg:text-[1.6rem]">
          <div className="flex items-center gap-2">
            <div>
              <KTSVG path={toAbsoluteUrl('/media/svg/general/checkmark.svg')} />
            </div>
            <div>Alle {variantProductInfo?.options?.cycle} Tage</div>
          </div>
          <div className="flex items-center gap-2">
            <div>
              <KTSVG path={toAbsoluteUrl('/media/svg/general/checkmark.svg')} />
            </div>
            <div>
              {variantProductInfo?.options?.pension === 'V' ? 'Vollpension' : 'Halbpension'}
            </div>
          </div>
        </div>
        <div className="flex justify-start mb-[2.5rem] font-medium text-left">
          <ul>
            {variantProductInfo?.sachets?.map((sachet: ISachet) => {
              if (sachet.quantity === 0) return null;
              const sachetTypeText = (SachetType as any)[sachet.type] || sachet.type;
              return (
                <li className="mb-[8px]" key={sachet.type} data-key={sachet.type}>
                  {sachet.quantity} x {sachet.weight.value}
                  {sachet.weight.unit} {sachetTypeText} (reicht {sachet.serve} Tage)
                </li>
              );
            })}
          </ul>
        </div>

        <div className="flex items-center lg:gap-[3px] mb-[2.5rem] text-left">
          <div className="flex items-center">
            <KTSVG
              svgClassName="date-icon"
              path={toAbsoluteUrl('/media/svg/orders/delivery-rounded.svg')}
            />

            <div className="lg:hidden">
              Geplanter Liefertermin: <br />
              <span className="font-bold">
                <FormattedDate value={dateFrom} day="2-digit" /> -{' '}
                <FormattedDate value={dateTo} year="numeric" month="short" day="2-digit" />
              </span>
            </div>
          </div>
          <div className="hidden lg:block">
            Geplanter Liefertermin:
            <span className="font-bold ml-[5px]">
              <FormattedDate value={dateFrom} day="2-digit" /> -{' '}
              <FormattedDate value={dateTo} year="numeric" month="short" day="2-digit" />
            </span>
          </div>
        </div>

        <Discount />
      </div>
    </Widget>
  );
};
