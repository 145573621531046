import { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getAuthTokenSelector } from '../core/selectors';
import { authRefresh } from '../core/actions';
import { readToken } from '../utils/readToken';

interface IUseAuthHook {
  refreshToken: VoidFunction;
}

export const useAuth = (): IUseAuthHook => {
  const dispatch = useDispatch();
  const accessToken = useSelector(getAuthTokenSelector);

  const refreshToken = useCallback(() => {
    if (!accessToken) return;

    const accessTokenDecoded = readToken(accessToken);

    const currentDateTimestamp = Math.round(new Date().getTime() / 1000);
    if (accessTokenDecoded?.exp && currentDateTimestamp > +accessTokenDecoded?.exp - 30)
      dispatch(authRefresh({ accessToken }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, dispatch]);

  useEffect(() => {
    const scheduleTokenRefresh = setInterval(refreshToken, 60000);

    return () => {
      clearInterval(scheduleTokenRefresh);
    };
  }, [refreshToken]);

  return { refreshToken };
};
