import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { authLogout } from './core/actions';
import { getAuthTokenSelector, getPendingSelector } from './core/selectors';
import { Loading } from '@components/index';

export function Logout() {
  const dispatch = useDispatch();
  const accessToken = useSelector(getAuthTokenSelector);
  const pending = useSelector(getPendingSelector);

  useEffect(() => {
    if (accessToken) dispatch(authLogout({ accessToken }));
  }, [dispatch, accessToken]);

  return accessToken || pending ? <Loading /> : <Navigate to="/auth" replace />;
}
