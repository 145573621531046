import React, { useEffect } from 'react';
import { Headline } from '@components/index';
import { Section } from '@layout/components';
import { getCustomerSelector } from '@modules/customer';
import { useSelector } from 'react-redux';

const RafPage = () => {
  const customer = useSelector(getCustomerSelector);

  useEffect(() => {
    function initReferralCandy() {
      try {
        var js: HTMLScriptElement = document.createElement('script');
        js.id = 'refcandy-candyjar-js';
        js.src = '//portal.referralcandy.com/assets/widgets/refcandy-candyjar.js';

        document.head.appendChild(js);
      } catch (error) {
        console.error('Error initializing Referral Candy script:', error);
      }
    }

    initReferralCandy();
  }, []);

  return (
    <>
      <Section>
        <Headline>Freu(n)de empfehlen</Headline>
        <div>
          <iframe
            id="refcandy-candyjar"
            // prod data-id:
            data-id="hn6oimfp9qlzh0eo7tpmd3ibx"
            // dev data-d:
            // data-id="swxpm6iuj5akr2s2nm2llpgrx"
            data-height="900"
            data-width="100%"
            data-email={customer?.email}
            data-fname={customer?.firstName}
            data-lname={customer?.lastName}
            data-locale="de"
            frameBorder={0}
          ></iframe>
        </div>
      </Section>
    </>
  );
};

export default RafPage;
