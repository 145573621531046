import clsx from 'clsx';
import { ReactNode } from 'react';

export type TabSummaryProps = {
  children: ReactNode;
  id: string | number;
  handleActive?: any;
  active: boolean;
};

const TabSummary = ({ children, id, handleActive, active }: TabSummaryProps) => {
  return (
    <h4 className={clsx('tab-header', { show: active })}>
      <button
        className="relative flex items-center w-full tab-button"
        type="button"
        onClick={() => handleActive(id)}
      >
        {children}
      </button>
    </h4>
  );
};

export { TabSummary };
