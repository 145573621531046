import { sachetIcons, sachetTypeConverted } from '@constants/common';
import { KTSVG, toAbsoluteUrl } from '@helpers/index';
import { getVariantProductInfoSelector } from '@modules/products/core/selectors';
import {
  getDefaultSubscriptionSelector,
  getDiscountSelector,
  getPetSelector,
} from '@modules/subscriptions/core/selectors';
import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useClickOutside } from 'src/shared';

export const SubscriptionReactivationRecipes = () => {
  const pet = useSelector(getPetSelector);
  const defaultSubscription = useSelector(getDefaultSubscriptionSelector);
  const productInfo = useSelector(getVariantProductInfoSelector);
  const discount = useSelector(getDiscountSelector);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const ref = useRef<HTMLDivElement | null>(null);

  useClickOutside(ref, () => {
    setIsDropdownOpen(false);
  });

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className="subscription-reactivation-recipes">
      <button className="subscription-reactivation-dropdown " onClick={handleDropdownToggle}>
        {pet?.name} erhält dies mit ihrer nächsten Bestellung{' '}
        <span className={`${!isDropdownOpen ? '' : 'rotate-180'} transition-transform`}>
          <KTSVG path={toAbsoluteUrl('/media/svg/general/down-arrow.svg')} />
        </span>
      </button>

      {isDropdownOpen && (
        <div>
          <p className="subscription-reactivation-dropdown-title">Mehrere Sorten</p>
          <ul className="flex items-center gap-[2rem] md:gap-[5rem] text-left subscription-reactivation-dropdown-items">
            {productInfo?.sachets?.map((sachet: any, index: any) => {
              if (sachet.quantity === 0) return null;
              const sachetType = sachetTypeConverted[sachet.type] || sachet.type;
              const sachetIcon = sachetIcons[sachet.type.toLowerCase()];
              return (
                <li
                  className="flex gap-[0.5rem] md:gap-[1rem] items-center sachet-list"
                  key={index}
                  data-key={sachet.type}
                >
                  {sachetIcon && <img src={sachetIcon} alt={sachetType} className="sachet-icon" />}
                  <div className="text-left">
                    <span className="sachet-list-text">{sachetType}</span> <br />
                    <span className="sachet-list-content">
                      {sachet.quantity}x{sachet.weight.value}
                      {sachet.weight.unit}
                    </span>
                  </div>
                </li>
              );
            })}
          </ul>
          <div className="dashed-border"></div>
        </div>
      )}

      {discount && (
        <div className="subscription-reactivation-discount">
          <p>Rabatt</p>
          <span>{discount?.title} </span>
        </div>
      )}

      <div className="subscription-reactivation-price">
        <div className="subscription-reactivation-price-info">
          SUMME
          <div>Incl. Mwst</div>
        </div>

        <div className="subscription-reactivation-price-summary">
          {discount && (
            <p>
              €{defaultSubscription?.totalLineItemPrice.toLocaleString('de-DE').replace('.', ',')}
            </p>
          )}
          <span>
            €
            {defaultSubscription?.totalLineItemDiscountedPrice
              .toLocaleString('de-DE')
              .replace('.', ',')}
          </span>
        </div>
      </div>
    </div>
  );
};
