import { useLocation } from 'react-router-dom';

interface IUseQuery {
  getQueryParam: (param: string) => string | null;
}

export const useQuery = (): IUseQuery => {
  const location = useLocation();

  const getQueryParam = (param: string) => {
    const queryParams = new URLSearchParams(location.search);
    return queryParams.get(param);
  };

  return {
    getQueryParam,
  };
};
