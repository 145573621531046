import { Outlet, Route, Routes } from 'react-router-dom';
import { LoginViaSession } from './components/LoginViaSession';
import { Login } from './components/Login';
import { useQuery } from 'src/shared';
import { ReLogin } from './components/ReLogin';

type AuthPageProps = {
  shouldRelogin: boolean;
};

const AuthLayout = () => {
  return (
    <div className="h-full">
      <Outlet />
    </div>
  );
};

export const AuthPage = ({ shouldRelogin }: AuthPageProps) => {
  const { getQueryParam } = useQuery();
  const sessionToken = getQueryParam('sessionToken');

  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route path="login" element={<Login />} />
        <Route path="verify" element={<LoginViaSession />} />
        <Route path="relogin" element={<ReLogin />} />
        <Route
          index
          element={shouldRelogin ? <ReLogin /> : sessionToken ? <LoginViaSession /> : <Login />}
        />
      </Route>
    </Routes>
  );
};
