import { all, call, put, takeLatest } from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';
import {
  fetchProductFailure,
  fetchProductSuccess,
  fetchVariantSuccess,
  fetchVariantFailure,
  fetchProductRequest,
  fetchVariantRequest,
} from './actions';
import { FETCH_PRODUCT_REQUEST, FETCH_VARIANT_REQUEST } from './actionTypes';

const getProduct = (productId: number) => axios.get<IProduct[]>(`/api/v2/products/${productId}`);

const getVariant = (productId: number, variantId: number) =>
  axios.get<IProduct[]>(`/api/v2/products/${productId}/variants/${variantId}`);

function* fetchProductSaga(action: ReturnType<typeof fetchProductRequest>) {
  try {
    const response: AxiosResponse<IProduct[]> = yield call(() =>
      getProduct(action.payload?.productId!),
    );
    yield put(
      fetchProductSuccess({
        products: response.data,
      }),
    );
  } catch (e: any) {
    yield put(
      fetchProductFailure({
        error: e.message,
      }),
    );
  }
}

function* fetchVariantSaga(action: ReturnType<typeof fetchVariantRequest>) {
  try {
    const response: AxiosResponse<IVariant[]> = yield call(() =>
      getVariant(action.payload?.productId!, action.payload?.variantId!),
    );
    yield put(
      fetchVariantSuccess({
        variants: response.data,
      }),
    );
  } catch (e: any) {
    yield put(
      fetchVariantFailure({
        error: e.message,
      }),
    );
  }
}

export function* productsSaga() {
  yield all([
    takeLatest(FETCH_PRODUCT_REQUEST, fetchProductSaga),
    takeLatest(FETCH_VARIANT_REQUEST, fetchVariantSaga),
  ]);
}
