import { toast } from 'react-toastify';
import { TOAST_CONFIG } from '@constants/common';

export const notifySuccess = (message: string) =>
  toast(message, {
    ...TOAST_CONFIG,
    type: 'success',
    className: 'toastify-hb-success',
  });

export const notifyError = (message: string) =>
  toast(message, {
    ...TOAST_CONFIG,
    type: 'error',
    className: 'toastify-hb-error',
  });
