import React, { useState } from 'react';
import { getPetSelector } from '@modules/subscriptions/core/selectors';
import { useSelector } from 'react-redux';
import { SupplementItem } from './SupplementItem';
import { ALL_SUPPLEMENTS, SUPPLEMENT_SLIDER } from '@constants/sliders';
import { Swiper, SwiperSlide } from 'swiper/react';

export const Supplements: React.FC = () => {
  const pet = useSelector(getPetSelector);
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleSlideChange = (swiper: any) => {
    setCurrentSlide(swiper.realIndex);
  };

  const currentBoxShadow = ALL_SUPPLEMENTS[currentSlide]?.boxShadow || 'none';
  const prevSlide = (currentSlide - 1 + ALL_SUPPLEMENTS.length) % ALL_SUPPLEMENTS.length;

  return (
    <div className="supplements" style={{ boxShadow: currentBoxShadow }}>
      <h3 className="supplements-title">
        MÖCHTEST DU{' '}
        <span>
          GESUNDE, FUNKTIONALE <br /> SNACKS{' '}
        </span>
        FÜR {pet?.name} PROBIEREN?
      </h3>
      <div className="supplement-slider-wrapper">
        <Swiper {...SUPPLEMENT_SLIDER} onSlideChange={handleSlideChange}>
          {ALL_SUPPLEMENTS.map((supplement, index) => (
            <SwiperSlide key={supplement.id}>
              <SupplementItem
                item={supplement}
                isActive={index === currentSlide}
                isPrev={index === prevSlide}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="supplement-slider-dots"></div>
        <div className="flex justify-end md:justify-between -mt-[1.1rem] mx-[0.5rem]">
          <div className="custom-swiper-button-next"></div>
          <div className="custom-swiper-button-prev"></div>
        </div>
      </div>

      <a
        href="https://www.hellobello.de/collections/hundekekse"
        target="_blank"
        className="supplements-link"
      >
        <span className="!text-white bg-secondary uppercase">Jetzt einkaufen</span>
      </a>
    </div>
  );
};
