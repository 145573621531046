import { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { getDefaultSubscriptionSelector } from '@modules/subscriptions/core/selectors';
import { useClickOutside } from 'src/shared/hooks/useClickOutside';
import { KTSVG, toAbsoluteUrl } from '@helpers/index';
import { getPaymentDetailsSelectorById } from '@modules/payment/core/selectors';

export const Payments = () => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);

  const defaultSubscription = useSelector(getDefaultSubscriptionSelector);
  const paymentDetails = useSelector(
    getPaymentDetailsSelectorById(defaultSubscription?.paymentMethodId),
  );

  useClickOutside(ref, () => setIsOpen(false));

  return (
    <>
      {paymentDetails?.card && (
        <>
          <div className="payment-card">
            <span className="secondary-info">
              **** **** **** {paymentDetails?.card?.lastDigits}
            </span>
            <span className="main-info">**** **** **** {paymentDetails?.card?.lastDigits}</span>
          </div>
          <div className="flex flex-col items-end justify-end payment-exp-info">
            <span className="-mb-[1rem]"> Monat/Jahr</span>

            <p className="flex gap-4">
              <span>gultig bis </span>
              {paymentDetails?.card?.expiryMonth?.toString().padStart(2, '0')}/
              {paymentDetails?.card?.expiryYear}
            </p>
          </div>
        </>
      )}
      {paymentDetails?.payPal && (
        <>
          <div className="z-10 payment-active payment-active-paypal">
            <KTSVG path={toAbsoluteUrl('/media/svg/payment/paypal.svg')} />
          </div>
          <p className="payment-expiration">{paymentDetails?.payPal?.email}</p>
        </>
      )}
    </>
  );
};
