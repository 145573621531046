import { useSelector } from 'react-redux';
import { getDefaultSubscriptionSelector } from '@modules/subscriptions/core/selectors';
import { SubscriptionReactivate } from './subscription-reactivate/SubscriptionReactivate';
import { SubscriptionResume } from './subscription-resume/SubscriptionResume';

export const SubscriptionReactivation = () => {
  const defaultSubscription = useSelector(getDefaultSubscriptionSelector);

  return (
    <>
      {defaultSubscription?.status === 'CANCELLED' && <SubscriptionReactivate />}
      {defaultSubscription?.status === 'PAUSED' && <SubscriptionResume />}
    </>
  );
};
