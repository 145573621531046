import React, { ReactNode } from 'react';
import { toAbsoluteUrl } from '@helpers/AssetHelpers';

interface UpdateSubscriptionItemProps {
  src?: string;
  alt?: string;
  xlSrc?: string;
  headline?: string;
  children?: ReactNode;
  className?: string;
}

export const UpdateSubscriptionItem: React.FC<UpdateSubscriptionItemProps> = ({
  src = '',
  alt = '',
  xlSrc = '',
  headline = '',
  children,
  className = '',
}) => {
  return (
    <div className="flex items-center md:block justify-between md:mx-auto gap-[1.1rem] md:gap-[1rem] mb-[0.8rem] md:mb-0 update-subscription-item">
      <div className="flex items-center md:justify-center">
        <div className="flex items-center md:block">
          <picture>
            <source media="(min-width: 1279px)" srcSet={toAbsoluteUrl(xlSrc)} />

            <img className={`test-package-food ${className}`} src={toAbsoluteUrl(src)} alt={alt} />
          </picture>
          <h3 className="intro-info-subheadline">{headline}</h3>
        </div>
      </div>
      <div className="flex items-center justify-center mx-auto text-center">{children}</div>
    </div>
  );
};
