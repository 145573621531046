import {
  FETCH_PRODUCT_REQUEST,
  FETCH_PRODUCT_SUCCESS,
  FETCH_PRODUCT_FAILURE,
  FETCH_VARIANT_REQUEST,
  FETCH_VARIANT_SUCCESS,
  FETCH_VARIANT_FAILURE,
} from './actionTypes';

import { ProductsActions, ProductsState } from './types';

const initialState: ProductsState = {
  pending: false,
  data: { product: null },
  error: null,
};

export const productsReducer = (state = initialState, action: ProductsActions) => {
  switch (action.type) {
    case FETCH_PRODUCT_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_PRODUCT_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.payload.products,
        error: null,
      };
    case FETCH_PRODUCT_FAILURE:
      return {
        ...state,
        pending: false,
        data: [],
        error: null,
      };

    case FETCH_VARIANT_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case FETCH_VARIANT_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.payload.variants,
        error: null,
      };
    case FETCH_VARIANT_FAILURE:
      console.warn(action);
      return {
        ...state,
        pending: false,
        data: [],
        error: null,
      };

    case 'persist/REHYDRATE':
      return {
        ...state,
        data:
          action.payload && action.payload.products && action.payload.products.data
            ? action.payload.products.data
            : [],
      };

    default:
      return {
        ...state,
      };
  }
};
