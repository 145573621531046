import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { KTSVG, toAbsoluteUrl } from '@helpers/index';
import { getFlowBannerTextSelector } from '@modules/subscriptions/core/selectors';
import { rewardConfetti } from '@constants/common';

export const RewardBanner = () => {
  const flowBannerText = useSelector(getFlowBannerTextSelector);
  const [showConfetti, setShowConfetti] = useState(false);
  const bannerRef = useRef<HTMLDivElement>(null);

  const decodeHtmlEntities = (str: string) => {
    const textArea = document.createElement('textarea');
    textArea.innerHTML = str;
    return textArea.value;
  };

  const decodedFlowBannerText = decodeHtmlEntities(flowBannerText);

  const discountMatch =
    typeof decodedFlowBannerText === 'string' ? decodedFlowBannerText.match(/(\d+)%/i) : null;
  const discountPercentage = discountMatch ? discountMatch[1] : '';

  const triggerConfetti = () => {
    if (bannerRef.current) {
      const rect = bannerRef.current.getBoundingClientRect();
      const x = (rect.left + rect.right) / 2 / window.innerWidth;
      const y = (rect.top + rect.bottom) / 2 / window.innerHeight;
      const offsetX = window.innerWidth <= 768 ? 0.1 : 0.01;
      rewardConfetti(x, y, offsetX);
    }
    setShowConfetti(true);
    setTimeout(() => setShowConfetti(false), 3000);
  };

  const handleClick = () => {
    triggerConfetti();
  };

  useEffect(() => {
    triggerConfetti();
  }, []);

  return (
    <div className="reward-banner h-[14.5rem] md:h-full text-white rounded-[3rem] lg:rounded-tr-[0] lg:rounded-br-[0] relative">
      <div className="flex gap-[2rem]">
        <h2 className="reward-banner-title">GLÜCKWUNSCH</h2>
        {discountPercentage && (
          <div className="reward-banner-ribbon">
            <KTSVG path={toAbsoluteUrl('/media/svg/reward/reward-banner.svg')} />
            <p>
              {discountPercentage}% <span>Rabatt</span>
            </p>
          </div>
        )}
      </div>
      <p className="reward-banner-message">{decodedFlowBannerText}</p>
      {showConfetti && (
        <div className="reward-banner-confetti">
          <canvas id="confetti-canvas"></canvas>
        </div>
      )}
      <div
        ref={bannerRef}
        className="absolute bottom-0 cursor-pointer right-6 reward-banner-animated"
        onClick={handleClick}
      >
        <picture>
          <source
            media="(max-width: 768px)"
            srcSet={toAbsoluteUrl(`/media/svg/reward/reward-mobile.svg`)}
            width="90px"
            height="100px"
          />
          <source
            media="(min-width: 768px)"
            srcSet={toAbsoluteUrl(`/media/svg/reward/reward-desktop.svg`)}
            width="151px"
            height="150px"
          />
          <source
            media="(min-width: 1279px)"
            srcSet={toAbsoluteUrl(`/media/svg/reward/reward-desktop.svg`)}
            width="190px"
            height="230px"
          />
          <img src={toAbsoluteUrl(`/media/svg/reward/reward-desktop.svg`)} alt="Reward" />
        </picture>
      </div>
      <span className="reward-banner-fade">Geschenkkarte</span>
    </div>
  );
};
