import { getCustomerSelector } from '@modules/customer';
import { toAbsoluteUrl } from '../../../../../shared/helpers/index';
import { useSelector } from 'react-redux';
import { getPetSelector } from '@modules/subscriptions/core/selectors';
import { RafLink } from '../raf-link/RafLink';

export const WelcomeMessage = () => {
  const pet = useSelector(getPetSelector);
  const customer = useSelector(getCustomerSelector);

  return (
    <div className="welcome-message">
      <div className="welcome-message-wrapper ">
        <div className="welcome-message-cloud md:flex">
          <div className="md:w-7/12 lg:w-3/5 xl:w-2/3 ">
            <h3 className="md:hidden">
              WILLKOMMEN <br /> in deinem Pfotenportal
              {customer?.firstName ? `, ${customer?.firstName}` : null}!
            </h3>
            <div className="flex gap-[5px] w-full justify-between">
              <div className="welcome-wrapper">
                <p className="welcome-message-cloud-extra">
                  Hier dreht sich alles um das <span>Leben und die gesunde Ernährung</span> von
                  <span> {pet?.name}</span>.
                </p>
                <div className="md:hidden">
                  <RafLink />
                </div>
              </div>
              <div className="welcome-message-cloud-image-wrapper md:hidden">
                <img src={toAbsoluteUrl('/media/img/misc/welcome-desktop.png')} alt="welcome" />
              </div>
            </div>
          </div>
          <div className="hidden md:block md:w-5/12 welcome-message-cloud-image lg:w-2/5 xl:w-1/3">
            <div className="welcome-message-cloud-image-wrapper">
              <img src={toAbsoluteUrl('/media/img/misc/welcome-desktop.png')} alt="welcome" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
