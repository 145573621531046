import axios from 'axios';
import { authRefresh } from '@modules/auth/core/actions';

const setupAxios = (store: any) => {
  axios.defaults.baseURL = process.env.REACT_APP_API_URI;
  axios.defaults.withCredentials = true;

  axios.interceptors.request.use(
    (config) => {
      const state = store.getState();
      const accessToken = state.auth.data?.accessToken;
      if (accessToken) {
        config.headers = config.headers || {};
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;

      if (error.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const currentState = store.getState();
        const currentAccessToken = currentState.auth.data?.accessToken;

        store.dispatch(authRefresh({ accessToken: currentAccessToken }));

        const waitForTokenRefresh = new Promise((resolve, reject) => {
          const checkState = setInterval(() => {
            const newState = store.getState();
            const newAccessToken = newState.auth.data?.accessToken;
            if (newAccessToken !== currentAccessToken) {
              clearInterval(checkState);
              resolve(newAccessToken);
            }
          }, 100);
        });

        try {
          const newAccessToken = await waitForTokenRefresh;
          originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
          return axios(originalRequest);
        } catch (error) {
          console.log('Token refresh failed:', error);
        }
      }

      return Promise.reject(error);
    },
  );
};

export default setupAxios;
