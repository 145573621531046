import {
  FETCH_ORDER_HISTORY_REQUEST,
  FETCH_ORDER_HISTORY_FAILURE,
  FETCH_ORDER_HISTORY_SUCCESS,
} from './actionTypes';
import {
  FetchOrderHistoryRequest,
  FetchOrderHistorySuccess,
  FetchOrderHistorySuccessPayload,
  FetchOrderHistoryFailure,
  FetchOrderHistoryFailurePayload,
  FetchOrderHistoryRequestPayload,
} from './types';

export const fetchOrderHistoryRequest = (
  payload: FetchOrderHistoryRequestPayload,
): FetchOrderHistoryRequest => ({
  type: FETCH_ORDER_HISTORY_REQUEST,
  payload,
});

export const fetchOrderHistorySuccess = (
  payload: FetchOrderHistorySuccessPayload,
): FetchOrderHistorySuccess => ({
  type: FETCH_ORDER_HISTORY_SUCCESS,
  payload,
});

export const fetchOrderHistoryFailure = (
  payload: FetchOrderHistoryFailurePayload,
): FetchOrderHistoryFailure => ({
  type: FETCH_ORDER_HISTORY_FAILURE,
  payload,
});
