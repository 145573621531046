import React, { useEffect, useRef } from 'react';
import clsx from 'clsx';
import { KTSVG, toAbsoluteUrl } from '@helpers/index';
import { Portal } from '@components/index';
import { useClickOutside } from 'src/shared/hooks/useClickOutside';
import { useSelector } from 'react-redux';
import { getFlowBannerSelector } from '@modules/subscriptions/core/selectors';

interface UpcomingRewardFlyoutProps {
  isModalOpen: boolean;
  toggleModal: () => void;
  discountPercentage: string;
  flowBannerText: string | null;
}

export const UpcomingRewardFlyout: React.FC<UpcomingRewardFlyoutProps> = ({
  isModalOpen,
  toggleModal,
  discountPercentage,
  flowBannerText,
}) => {
  const flowBanner = useSelector(getFlowBannerSelector);

  const decodeHtmlEntities = (str: any) => {
    const textArea = document.createElement('textarea');
    textArea.innerHTML = str;
    return textArea.value;
  };

  const decodedFlowBannerText = decodeHtmlEntities(flowBannerText);
  const decodedFlowBanner = decodeHtmlEntities(flowBanner);

  const discountMatch =
    decodedFlowBanner && typeof decodedFlowBanner === 'string'
      ? decodedFlowBanner.match(/(\d+)%/)
      : null;

  const discount = discountMatch ? discountMatch[1] : '';

  const ref = useRef(null);

  useClickOutside(ref, () => {
    if (isModalOpen) {
      toggleModal();
    }
  });

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && isModalOpen) {
        toggleModal();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isModalOpen, toggleModal]);

  return (
    <>
      {isModalOpen && (
        <>
          <Portal className="reward-banner-backdrop">
            <></>
          </Portal>

          <div
            ref={ref}
            className={clsx(
              'reward-banner reward-banner-mobile !bg-white md:hidden text-white rounded-tl-[3rem] rounded-bl-[3rem] xl:gap-16 flex items-center relative min-h-[16rem]',
              {
                'reward-banner-sticky': isModalOpen,
                'slide-in': isModalOpen,
                'slide-out': !isModalOpen,
              },
            )}
          >
            <button className="reward-sticky-close md:hidden" onClick={toggleModal}>
              {discountPercentage ? `${discountPercentage}% Rabatt` : '% Rabatt'}
            </button>
            <div>
              <div className="flex gap-[2rem]">
                <h2 className="reward-banner-title">FAST GESCHAFFT!</h2>
                <div className="reward-banner-ribbon"></div>
              </div>
              <p className="reward-banner-message-almost">{decodedFlowBannerText}</p>

              <span className="reward-banner-fade">Geschenkkarte</span>
            </div>
            <div className="relative">
              <div className="w-[16rem] h-[13.2rem]">
                <KTSVG
                  path={toAbsoluteUrl(
                    `/media/svg/reward/reward-discount-${discountPercentage}.svg`,
                  )}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
